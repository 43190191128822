<template>
  <div>
    <div v-if="channelInfo">
      <div class="status_container" ref="channelBox">
        <div class="thumb" v-if="channelInfo.channelId">
          <div
            class="ch_info logo"
            :style="
              `background-color: #000; border-radius:${channelInfo.borderRadius ? channelInfo.borderRadius : '0'}px; `
            "
          >
            <div v-if="channelInfo.channelLogoUrl">
              <img :src="channelInfo.channelLogoUrl || ''" @error="thumbLoadFailed" style="width: 60%" />
            </div>
            <span class="ch_name" v-if="channelInfo.channelNumber">{{ 'IP-' + channelInfo.channelNumber }}</span>
            <span class="ch_name" style="font-size: 0.5rem" v-else>{{ getChannelsTextdata('no-information') }}</span>
          </div>
        </div>
        <div class="txt_wrap" ref="infoWrapBox">
          <div class="program-container">
            <div class="program-info_details">
              <div class="ellipsis" ref="infoTitleBox">
                {{
                  `${
                    programInfo && (programInfo.programTitle || programInfo.title)
                      ? programInfo.programTitle || programInfo.title
                      : getChannelsTextdata('no-information')
                  }`
                }}
              </div>
              <br />
              <div v-if="isVodContent(programInfo)">
                <p v-if="programInfo.contentType === 'TVSHOW-SERIES'">{{ getSeasonDetails(programInfo) }}</p>
                <p>
                  {{ programInfo.releaseDate }}
                  {{ programInfo?.rating == 'off' ? '' : programInfo?.rating }} {{ ' ' }}
                  {{ programInfo.genreName }}
                  {{ ' ' }}
                  {{ calculateDurationInHHMM(programInfo.duration) }} {{ ' ' }}
                  {{ programInfo.captionFlag ? 'CC' : '' }}
                </p>
                <p v-if="programInfo.directors || programInfo.actors">
                  {{
                    programInfo.directors
                      ? programInfo.directors != ('null' || '')
                        ? 'Director - ' + programInfo.directors
                        : ''
                      : ''
                  }}
                  <br />
                  {{
                    programInfo.actors
                      ? programInfo.actors != ('null' || '')
                        ? 'Cast - ' + programInfo.actors
                        : ''
                      : ''
                  }}
                </p>
              </div>
            </div>
            <div v-if="isVodContent(programInfo)" class="favorite-button">
              <div class="favorite-icon">
                <i
                  @click="toggleFavorite(programInfo)"
                  class="fa"
                  :class="['fa-star', isFavorite ? 'whitish' : 'greyed']"
                ></i>
              </div>
            </div>
          </div>
          <div v-if="isVodContent(programInfo)">
            <span class="ch_desc" v-if="programInfo.description">{{ programInfo.description }}</span>
          </div>
          <span v-if="!isScheduleAreaOverflown && channelInfo.channelId" class="time ellipsis" ref="timeBox">
            {{ getProgramSchedule(programInfo) }}</span
          >
        </div>
      </div>
      <div class="controls" v-if="channelInfo.channelId">
        <button
          id="information_btn"
          ref="information_btn"
          type="button"
          class="btn btn_information btn_modal_pop"
          :disabled="!channelInfo.channelId || !programInfo"
          @click="openProgramPopUp"
          @keydown.prevent.enter="openProgramPopUp"
        >
          {{ getTextdata('info') }}
        </button>
        <button
          type="button"
          class="btn btn_sharing"
          id="sharing_btn"
          ref="sharing_btn"
          :disabled="!shareData.channelId"
          @click="openSharePopUp"
          @keydown.prevent.enter="openSharePopUp"
        >
          {{ getTextdata('share') }}
        </button>
      </div>
    </div>

    <div v-if="channelInfo.channelId">
      <!-- 공유 팝업 -->
      <sharePopUp :shareData="shareData"></sharePopUp>
      <!-- 프로그램 정보 팝업 -->
      <programPopUp
        v-if="programPopUpData.open"
        :popUpData="programPopUpData"
        :programInfo="$store.state.programInfo"
        :channelInfo="channelInfo"
      ></programPopUp>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sharePopUp, programPopUp } from './index.js';

export default {
  props: {
    channelInfo: Object,
    programInfo: Object,
    programInfoDisplay: String,
  },
  components: {
    sharePopUp,
    programPopUp,
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      programPopUpData: 'getProgramPopupData',
      popUpStatus: 'getPopupStatus',
      isPopupOpened: 'getPopupOpened',
    }),
    isTitleMarquee() {
      return !(!this.domReady || this.isPopupOpened);
    },
    isDescriptionMarquee() {
      return !(!this.domReady || this.isPopupOpened);
    },
    getTextdata() {
      return whattext => {
        return this.$t(`components.programInfo.${whattext}`) ?? '';
      };
    },
    getChannelsTextdata() {
      return whattext => {
        return this.$t(`components.channels.${whattext}`) ?? '';
      };
    },
    getProgramSchedule() {
      return program => {
        const _fmt =
          this.screenSize.width > 800 || (this.screenSize.width > 450 && this.screenMode === 'portrait')
            ? 'MM/DD hh:mm A'
            : 'hh:mm A';
        if (program?.startDateTime && program?.endDateTime) {
          return `${this.$moment(new Date(program.startDateTime)).format(_fmt)} - ${this.$moment(
            new Date(program.endDateTime)
          ).format(_fmt)}`;
        } else {
          return this.getChannelsTextdata('no-information');
        }
      };
    },

    isScheduleAreaOverflown() {
      return this.screenSize.width < 350 || (this.screenSize.width < 750 && this.screenMode === 'landscape');
    },
  },
  watch: {
    programInfoDisplay: 'startMarquee',
    screenSize: 'startMarquee',
    programInfo: 'startMarquee',
    isTitleMarquee: {
      handler(newVal) {
        if (!newVal) {
          this.$refs.infoTitleBox.style.animation = '';
        } else {
          this.startMarquee();
        }
      },
    },
    isDescriptionMarquee: {
      handler(newVal) {
        if (!this.$refs.infoDescriptionBox) return;
        if (!newVal) {
          this.$refs.infoDescriptionBox.style.animation = '';
        } else {
          this.startMarqueeDescription();
        }
      },
    },
    channelInfo: {
      deep: true,
      handler() {
        this.shareData.channelId = this.channelInfo.channelId;
      },
    },
    popUpData: {
      deep: true,
      handler(newVal) {
        this.$store.dispatch('setProgramPopupData', newVal);
      },
    },
    programPopUpData: {
      deep: true,
      handler(newVal) {
        if (!newVal.open && this.popUpData.open) {
          this.popUpData = newVal;
          if (newVal.forceFocus) {
            setTimeout(() => {
              this.$refs.information_btn.focus();
            }, 30);
          }
        }
      },
    },
    popUpStatus: {
      deep: true,
      handler(newVal) {
        if (newVal.type === 'share' && !newVal.open) {
          this.shareData.open = false;
          if (newVal.forceFocus) {
            setTimeout(() => {
              this.$refs.sharing_btn.focus();
            }, 30);
          }
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.domReady = true;
    }, 50);
    this.defaultImgSrc = require('../assets/images/thumb/default.png');
    this.isFavoriteContent(this.programInfo);
  },
  inject: ['beacon'],
  data() {
    return {
      shareData: { open: false, channelId: '' },
      popUpData: { open: false },
      domReady: false,
      isFavorite: false,
    };
  },
  methods: {
    getSeasonDetails(program) {
      let seasonInfo = '';
      seasonInfo = `S${program.seasonNumber} E${program.episodeNumber} ${program.title}`;
      console.log('season details', program);
      return seasonInfo;
    },
    calculateDurationInHHMM(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);
      return totalMinutes
        ? totalMinutes / 60 > 0
          ? `${totalMinutes % 60}m`
          : `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`
        : '';
    },
    getCamelCaseText(text) {
      text = text.toLowerCase();
      return text
        .replace(/(?:^\w|[A-Z]|\b\w)/g, match => {
          return match.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    startMarquee() {
      this.$nextTick(() => {
        this.$refs.infoTitleBox.style.animation = '';
        const width = this.$refs.channelBox.clientWidth / 70;
        var wrapperWidth = this.$refs.infoWrapBox.clientWidth;
        var titleWidth = this.$refs.infoTitleBox.clientWidth;
        if (wrapperWidth < titleWidth) {
          const seconds = (titleWidth / wrapperWidth) * width;
          this.$refs.infoTitleBox.style.animation = `marquee ${seconds}s linear infinite`;
        } else {
          this.$refs.infoTitleBox.style.animation = '';
        }
      });
    },
    startMarqueeDescription() {
      this.$nextTick(() => {
        if (this.$refs.infoDescriptionBox && this.$refs.infoDescriptionBox.style) {
          this.$refs.infoDescriptionBox.style.animation = '';
          const width = this.$refs.channelBox.clientWidth / 80;
          var wrapperWidth = this.$refs.infoWrapBox.clientWidth;
          var descriptionWidth = this.$refs.infoDescriptionBox.clientWidth;
          if (wrapperWidth < descriptionWidth) {
            const seconds = (descriptionWidth / wrapperWidth) * width;
            this.$refs.infoDescriptionBox.style.animation = `marquee ${seconds}s linear infinite`;
          } else {
            this.$refs.infoDescriptionBox.style.animation = '';
          }
        }
      });
    },
    openProgramPopUp(event) {
      // beacon
      this.beacon.saveDetail(this.programInfo, this.channelInfo);

      this.popUpData = {
        ...this.programPopUpData,
        top: null,
        open: true,
        forceFocus: event.type === 'keydown',
      };
    },
    openSharePopUp(event) {
      // beacon
      this.beacon.updateFunctionObject('share');

      this.shareData = {
        ...this.shareData,
        open: true,
        forceFocus: event.type === 'keydown',
      };
    },
    thumbLoadFailed(e) {
      e.target.src = this.defaultImgSrc;
    },
    toggleFavorite(program) {
      this.$emit('addToFavoriteVOD', program);
      this.isFavoriteContent(program);
    },
    isVodContent(program) {
      if (program?.type || program?.contentType) {
        const vodType = ['MOVIE', 'TVSHOW', 'TVSHOW-SERIES'];
        const programType = program?.type || program?.contentType;
        return vodType.findIndex(item => item === programType.toUpperCase()) > -1 ? true : false;
      }
      return false;
    },
    isFavoriteContent(program) {
      let favoriteVOD = JSON.parse(localStorage.getItem('favorite-vod')) || [];
      let contentId = program.contentId || program.vodId || program.seasonId;
      const existingIndex = favoriteVOD.findIndex(item => item.contentId === contentId);
      this.isFavorite = existingIndex > -1 ? true : false;
    },
  },
};
</script>

<style lang="scss">
.info-channel-logo-img {
  border-radius: 0px !important;
  width: 50% !important;
  height: 50% !important;
}
.ellipsis {
  color: white;
  font-family: 'LG SUXR', sans-serif !important;
}
.ellipsisDes {
  color: white;
  font-family: Hawaii 5-0, sans-serif !important;
  white-space: nowrap;
  display: inline-block;
  -webkit-overflow-scrolling: touch;
}

.program-container {
  display: flex;
}

.program-info_details {
  width: 85%;
}

.favorite-button {
  width: 15%;
  display: flex;
  align-items: top;
  justify-content: right;
}
</style>
