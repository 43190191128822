<template>
  <v-tabs
    touch
    id="menuTab"
    class="categoryTabCentre category-tab mobile-category"
    dark
    background-color="black"
    slider-size="2"
    center-active
    show-arrows
    @touchstart="scrollHide"
    @touchend="scrollAble"
  >
    <v-tabs-slider color="#bd0838"></v-tabs-slider>
    <v-tab
      class="tabFocus"
      tabindex="0"
      touch
      :ripple="false"
      v-for="(tab, i) in tabs.categories"
      :key="i"
      ref="tabRef"
      @click="changeTab(tab, i)"
      @focus="scrollFirst(i)"
      @touchstart="scrollHide"
      @touchmove="iosDevice ? scrollHide : ''"
      @touchend="scrollAble"
    >
      <span :style="tabItemStyle(i)" class="category-no-value">
        {{ tab.categoryName }}
      </span>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '../event-bus';

export default {
  props: {
    tabs: Object,
  },
  data() {
    return {
      iosDevice: false,
      tabPos: {},
    };
  },
  created() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.iosDevice = true;
    }
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
    }),
    tabItemStyle() {
      return index => {
        if (this.tabs.selected.index === index || this.tabs.selected === index) {
          return {
            fontWeight: 500,
            color: 'white',
          };
        } else {
          return {};
        }
      };
    },
  },
  mounted() {
    this.tabPositionChanged();
  },

  methods: {
    changeTab(tab, i) {
      this.tabs.selected = {
        index: i,
        timestamp: new Date().getTime(),
      };
      this.tabs.nowCategory = [tab];
      this.$emit('changeSelectedMenu', i);
      this.$router.push(tab);
      if (tab.name === 'ondemand') {
        eventBus.$emit('enterpip');
      } else {
        eventBus.$emit('disposePlayer');
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    scrollFirst(i) {
      if (i === 0) {
        for (let index = 0; index < this.tabs.categories.length / 4; index++) {
          document.getElementsByClassName('v-slide-group__prev')[0].click();
        }
      }
    },
    scrollHide() {
      document.documentElement.style.setProperty('overflow', 'hidden', 'important');
    },
    scrollAble() {
      document.documentElement.style.setProperty('overflow', 'auto', 'important');
    },
    tabPositionChanged() {
      this.tabPos = this.$refs.tabRef[0].$el.getBoundingClientRect();
      this.$emit('tabposition', this.tabPos);
    },
  },
};
</script>

<style lang="scss">
.categoryTabCentre {
  display: inline-flex;
  max-width: fit-content;
}
</style>
