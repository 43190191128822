<template>
  <div id="mainHeder">
    <!-- header -->
    <header class="header" ref="header" :class="{ VODIsEnabled: isVODStartSupport }">
      <h1 style="color:white;" class="mobile-header">
        <!-- @click="toMain" -->
        <span style="margin-left: 5px;"
          >{{ getTextdata('title_text1') }}<span>{{ getTextdata('title_text2') }}</span></span
        >
      </h1>
      <div class="middle middle-mobile" v-if="isVODStartSupport">
        <menuTab
          v-show="menuheader.categories.length > 1 && !isLoading"
          class="category"
          :class="{ topHeader: !isMobile() }"
          :tabs="menuheader"
          @changeSelectedMenu="changeSelectedMenu"
        />
      </div>

      <div v-show="ui_status" class="controls" v-if="search_status === STATUS.hidden">
        <div class="search_inner">
          <button
            type="button"
            id="header_search"
            ref="header_search"
            class="btn btn_search"
            @click="openSearchPopUp"
            @keydown.prevent.enter="openSearchPopUp"
            @keydown.tab.exact="goNext($event)"
          >
            {{ getTextdata('search') }}
          </button>
        </div>
      </div>
    </header>
    <!-- // header -->
    <!-- Popup : 검색 -->
    <!-- 22.04.15 수정 Start -->
    <div class="pop_dim" style="display:block" v-if="search_status !== STATUS.hidden" @click="closeSearchPopUp"></div>
    <div class="popup pop_search active" v-if="search_status !== STATUS.hidden" id="header-tab-trap">
      <!-- Step01 : 검색 결과 -->
      <div class="search_step01" v-if="search_status === STATUS.search_live || search_status === STATUS.search_upnext">
        <div class="search_item">
          <input
            type="text"
            class="search_field search_Selection"
            :placeholder="getTextdata('search-placeholder')"
            :aria-label="getTextdata('search-placeholder')"
            ref="search_field"
            id="search_field"
            @keydown.enter="searchStart"
            @keydown.prevent.shift.tab="goPrev"
            @keydown.prevent.tab.exact="goNext"
          />
          <button
            id="search_button"
            ref="search_button"
            type="button"
            class="btn btn_search"
            @click="searchStart"
            @keydown.prevent.shift.tab="goPrev"
            @keydown.prevent.tab.exact="goNext"
          >
            {{ getTextdata('search') }}
          </button>
        </div>
        <div class="search_result scroll01">
          <ul class="tabs">
            <li :class="{ active: search_status === STATUS.search_live }">
              <button
                ref="tab_live"
                id="tab_live"
                type="button"
                name="tab_live"
                role="tab"
                :aria-selected="search_status === STATUS.search_live ? 'true' : 'false'"
                @click="changeStatus(STATUS.search_live)"
                @keydown.prevent.shift.tab="goPrev"
                @keydown.prevent.tab.exact="goNext"
              >
                <span>{{ getTextdata('broadcasting') }}</span> ({{ broadcasting.length }})
              </button>
            </li>
            <li :class="{ active: search_status === STATUS.search_upnext }">
              <button
                ref="tab_upnext"
                id="tab_upnext"
                type="button"
                name="tab_upnext"
                role="tab"
                :aria-selected="search_status === STATUS.search_upnext ? 'true' : 'false'"
                @click="changeStatus(STATUS.search_upnext)"
                @keydown.prevent.shift.tab="goPrev"
                @keydown.prevent.tab.exact="goNext"
              >
                <span>{{ getTextdata('notBroadcasting') }}</span> ({{ notBroadcasting.length }})
              </button>
            </li>
            <span v-if="!broadcasting.length && !notBroadcasting.length" role="status" aria-live="polite">{{
              getTextdata('No results found')
            }}</span>
            <span v-else role="status" aria-live="polite">
              {{ getTextdata('broadcasting') }} {{ broadcasting.length }} {{ getTextdata('notBroadcasting') }}
              {{ notBroadcasting.length }}</span
            >
          </ul>
          <div class="tab_container" v-if="!noResult">
            <!-- tab : 현재방송중 -->
            <div id="tab_live" class="tab_content" :class="{ active: search_status === STATUS.search_live }">
              <ul class="channel_wrap">
                <li v-for="(casting, i) in broadcasting" :key="i">
                  <div
                    class="channel_item"
                    @click="programClicked($event, casting)"
                    @keydown.prevent.shift.tab="goPrev($event, i)"
                    @keydown.tab.exact="goNext($event, i)"
                    @keydown.prevent.space.enter="programClicked($event, casting)"
                    @mouseenter="listItemFocused(i)"
                    @mouseleave="listItemBlur"
                    @focus="listItemFocused(i)"
                    @blur="listItemBlur"
                    ref="live_item"
                    tabindex="-1"
                  >
                    <div class="ch_info logo">
                      <img :src="casting.logo" :alt="casting.num" />
                    </div>
                    <div class="ch_cont" ref="liveWrapBox">
                      <span class="ellipsis name" ref="liveTitleBox">{{ casting.title }} </span>
                      <span class="time"
                        ><span class="casting_num">{{ casting.num }}</span>
                        {{ casting.time }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- // tab : 현재방송중 -->

            <!-- tab : 방송예정 -->
            <div id="tab_upnext" class="tab_content" :class="{ active: search_status === STATUS.search_upnext }">
              <ul class="channel_wrap">
                <li v-for="(notCasting, i) in notBroadcasting" :key="i">
                  <div
                    class="channel_item"
                    @click="showInfo($event, notCasting)"
                    @keydown.prevent.space.enter="showInfo($event, notCasting)"
                    @keydown.prevent.shift.tab="goPrev($event, i)"
                    @keydown.tab.exact="goNext($event, i)"
                    @mouseenter="listItemFocused(i)"
                    @mouseleave="listItemBlur"
                    @focus="listItemFocused(i)"
                    @blur="listItemBlur"
                    ref="upnext_item"
                    tabindex="-1"
                  >
                    <div class="ch_info logo">
                      <img :src="notCasting.logo" alt="notCasting.num" />
                    </div>
                    <div class="ch_cont" ref="upnextWrapBox">
                      <span class="ellipsis name" ref="upnextTitleBox">
                        {{ notCasting.title }}
                      </span>
                      <span class="time"
                        ><span class="casting_num">{{ notCasting.num }}</span> {{ notCasting.time }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- // tab : 방송예정 -->
          </div>
          <!-- empty text -->
          <div v-else class="empty_txt">{{ getTextdata('no-result') }}</div>
          <!-- // empty text -->
        </div>
        <button
          type="button"
          class="btn btn_close popup_close"
          id="search_close"
          ref="search_close"
          @click="closeSearchPopUp"
          @keydown.prevent.enter="closeSearchPopUp"
          @keydown.prevent.shift.tab="goPrev"
          @keydown.prevent.tab.exact="goNext"
        >
          {{ getTextdata('close') }}
        </button>
      </div>
      <!-- // Step01 : 검색 결과 -->

      <!-- Step02 : 채널 정보 -->
      <div v-else class="search_step02">
        <div class="live_status">
          <div class="status_container">
            <div class="thumb">
              <div class="ch_info logo">
                <img :src="programInfo.logo" :alt="programInfo.num" />
              </div>
            </div>
            <div class="txt_wrap" ref="searchWrapBox">
              <div class="ellipsis" ref="searchTitleBox">
                {{ programInfo.title }}
              </div>
              <span class="time"
                ><span class="casting_num">{{ programInfo.num }}</span> {{ programInfo.time }}
              </span>
            </div>
          </div>
        </div>
        <div class="pg_info">
          {{ programInfo.content }}
        </div>

        <button
          type="button"
          class="btn btn_prev"
          id="info_prev_button"
          ref="info_prev_button"
          @click="hideInfo"
          @keydown.prevent.enter="hideInfo"
          @keydown.prevent.shift.tab="goPrev"
          @keydown.prevent.tab.exact="goNext"
        >
          {{ getTextdata('back') }}
        </button>
        <button
          type="button"
          class="btn btn_close popup_close"
          id="info_close_button"
          ref="info_close_button"
          @click="closeSearchPopUp"
          @keydown.prevent.enter="closeSearchPopUp"
          @keydown.prevent.shift.tab="goPrev"
          @keydown.prevent.tab.exact="goNext"
        >
          {{ getTextdata('close') }}
        </button>
      </div>
      <!-- // Step02 : 채널 정보 -->
    </div>
    <!-- 22.04.15 수정 End -->
    <!-- // Popup : 검색 -->
  </div>
</template>

<script>
import SI from '@/apis/service_interface';
import { mapGetters } from 'vuex';
import { menuTab } from '@/components/index';

// search status
export default {
  inject: ['beacon'],
  components: {
    menuTab,
  },
  props: {
    ui_status: Boolean,
  },
  data() {
    return {
      searchText: '',
      noResult: false,
      broadcasting: [],
      notBroadcasting: [],
      scroll: 0,
      resultClicked: false,
      header_height: 0,
      programInfo: null,
      focusIndex: -1,

      search_status: 0,
      STATUS: { hidden: 0, search_live: 1, search_upnext: 2, search_upnext_info: 3 },
      menuheader: {
        selected: 0,
        nowCategory: [],
        categories: [
          { categoryName: 'Live', name: 'live' },
          { categoryName: 'On demand', name: 'ondemand' },
        ],
      },
      isLoading: false,
      isVODStartSupport: false,
    };
  },
  created() {
    if (SI.mock) {
      this.broadcasting = [
        {
          logo: '',
          num: '412',
          title: '미운 우리 새끼 215회 미운 우리 새끼 215회 미운 우리 새끼 215회 미운 우리 새끼 215회',
          time: '15:00 ~ 17:00',
          content:
            '나정은 자신과 멀리 떨어져 지내는 쓰레기를 생각하면 안절부절이다. 한편 빙그레는 자신도 이제껏 확신하지 못한 감정들을 확인하게 된다. 야구에 전력투구하는 칠봉, 연애가 항상 불안한 삼천포와 윤진. 그들은 모두 각자의 방식대로 두려운 것들에 대해 맞설 준비를 한다.',
        },
        { logo: '', num: '123', title: '미운 우리 새끼 215회', time: '15:00 ~ 17:00', content: '프로그램 설명 2' },
        { logo: '', num: '444', title: '우리의 식탁', time: '15:00 ~ 17:00', content: '프로그램 설명 3' },
      ];
      this.notBroadcasting = [
        {
          logo: '',
          num: '412',
          title: '어느날 우리집 - 2화 아무도 없었다. 어느날 우리집 - 2화 아무도 없었다.',
          time: 'PM 15:00 ~ 17:00',
          content:
            '나정은 자신과 멀리 떨어져 지내는 쓰레기를 생각하면 안절부절이다. 한편 빙그레는 자신도 이제껏 확신하지 못한 감정들을 확인하게 된다. 야구에 전력투구하는 칠봉, 연애가 항상 불안한 삼천포와 윤진. 그들은 모두 각자의 방식대로 두려운 것들에 대해 맞설 준비를 한다.',
        },
        { logo: '', num: '123', title: '미운 우리 새끼 215회', time: 'PM 15:00 ~ 17:00', content: '프로그램 설명 2' },
        { logo: '', num: '444', title: '우리의 식탁', time: 'PM 15:00 ~ 17:00', content: '프로그램 설명 2' },
      ];
    }
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      popupStatus: 'getPopupStatus',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.mainHeader.${whattext}`) ?? '';
      };
    },
  },
  watch: {
    search_status: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        switch (newVal) {
          case this.STATUS.hidden:
            if (this.resultClicked) {
              // 채널 이동시 style
              document.documentElement.style.setProperty('top', '', 'important');
              window.scrollTo(0, this.scroll);
            }
            break;
          case this.STATUS.search_live:
            if (oldVal === this.STATUS.hidden) {
              this.resultClicked = false;
              // search popup start
              if (!SI.mock) {
                this.broadcasting = [];
                this.notBroadcasting = [];
              }
            }
            break;
          case this.STATUS.search_upnext_info:
            // search result -> program info
            this.searchTitleMarquee();
            break;
          case this.STATUS.search_upnext:
          default:
            // skip
            break;
        }
        this.$store.dispatch('setPopupStatus', {
          type: 'search',
          open: newVal !== this.STATUS.hidden,
        });
      },
    },
    focusIndex: {
      handler(newVal, oldVal) {
        let wrapBox, titleBox;
        switch (this.search_status) {
          case this.STATUS.search_live:
            wrapBox = 'liveWrapBox';
            titleBox = 'liveTitleBox';
            break;
          case this.STATUS.search_upnext:
            wrapBox = 'upnextWrapBox';
            titleBox = 'upnextTitleBox';
            break;
          default:
            // do nothing
            return;
        }
        if (oldVal >= 0) this.stopMarquee(titleBox, oldVal);
        if (newVal >= 0) this.startMarquee(wrapBox, titleBox, newVal);
      },
    },
    screenSize: {
      deep: true,
      handler() {
        if (this.search_status === this.STATUS.search_upnext_info) this.searchTitleMarquee();
        this.header_height = this.$refs.header?.offsetHeight ?? 0;
      },
    },
    header_height: {
      handler(newVal) {
        this.$emit('offsetHeight', newVal);
      },
    },
  },
  methods: {
    getActiveItems() {
      switch (this.search_status) {
        case this.STATUS.search_live:
          return this.$refs.live_item;
        case this.STATUS.search_upnext:
          return this.$refs.upnext_item;
        default:
          return null;
      }
    },
    changeStatus(status) {
      this.search_status = status;
    },
    openSearchPopUp(event) {
      // beacon
      this.beacon.updateFunctionObject('search');

      this.search_status = this.STATUS.search_live;

      if (event.type === 'keydown') {
        setTimeout(() => {
          this.$refs.search_field.focus();
        }, 100);
      }
    },
    closeSearchPopUp(event) {
      this.search_status = this.STATUS.hidden;
      if (event.type === 'keydown') {
        setTimeout(() => {
          this.$refs.header_search.focus();
        }, 100);
      }
    },
    searchStart() {
      if (SI.mock) {
        this.noResult = !this.noResult;
        return;
      }
      let keyword = this.$refs.search_field.value?.toLowerCase();
      if (!keyword) return;

      this.broadcasting = [];
      this.notBroadcasting = [];
      let allChannelList = this.$store.state.channelList;

      let result = allChannelList.reduce((acc, channel) => {
        // 채널명 or 프로그램명 검색
        let prgramsFound = channel.programs?.filter(program => {
          return (
            program.programTitle?.toLowerCase().indexOf(keyword) > -1 ||
            channel.channelName?.toLowerCase().indexOf(keyword) > -1
          );
        });

        prgramsFound.forEach(program => {
          acc.push({
            id: channel.channelId,
            logo: channel.channelLogoUrl,
            num: channel.channelNumber ? 'IP-' + channel.channelNumber : this.$t('components.channels.no-information'),
            title: program.programTitle,
            time: `${this.$moment(new Date(program.startDateTime)).format('hh:mm A')} - ${this.$moment(
              new Date(program.endDateTime)
            ).format('hh:mm A')}`,
            content: program.description,
            startDateTime: program.startDateTime,
            endDateTime: program.endDateTime,
          });
        });
        return acc;
      }, []);

      // broadcasting 구분
      let now = this.$moment(new Date());
      result.forEach(content => {
        if (now.isBetween(this.$moment(new Date(content.startDateTime)), this.$moment(new Date(content.endDateTime)))) {
          this.broadcasting.push(content);
        } else if (now.isBefore(this.$moment(new Date(content.startDateTime)))) {
          this.notBroadcasting.push(content);
        }
      });

      this.noResult = result.length > 0 ? false : true;
    },
    // toMain() {
    //   console.log('toMain');
    //   // if (this.$route.name !== 'live') {
    //   //   this.$router.push({ name: 'live' });
    //   // }
    // },
    showInfo(event, data) {
      this.programInfo = data;
      this.search_status = this.STATUS.search_upnext_info;
      if (event.type === 'keydown') {
        setTimeout(() => {
          this.$refs.info_prev_button.focus();
        }, 100);
      }
    },
    hideInfo(event) {
      this.search_status = this.STATUS.search_upnext;
      if (event.type === 'keydown') {
        let index = this.notBroadcasting.findIndex(data => data === this.programInfo);
        setTimeout(() => {
          this.$refs.upnext_item[index].focus();
        }, 100);
      }
    },
    programClicked(event, channel) {
      this.resultClicked = true;
      this.search_status = this.STATUS.hidden;
      this.$appbus.$emit('searchedChannel', {
        channelId: channel.id,
        eventType: event.type,
      });
    },
    goPrev(event, index) {
      let from = event.target.id;
      let items = this.getActiveItems();
      switch (from) {
        case 'search_field':
          // go last item
          if (!items || items.length === 0) {
            this.$refs.tab_upnext.focus();
          } else {
            items[items.length - 1].focus();
          }
          break;
        case 'search_button':
          // go text field
          this.$refs.search_field.focus();
          break;
        case 'search_close':
          // go search button
          this.$refs.search_button.focus();
          break;
        case 'tab_live':
          // go to close button
          this.$refs.search_close.focus();
          break;
        case 'tab_upnext':
          // go to tab_live
          this.$refs.tab_live.focus();
          break;
        case 'info_prev_button':
          // go to info_close_button
          this.$refs.info_close_button.focus();
          break;
        case 'info_close_button':
          // go to info_prev_button
          this.$refs.info_prev_button.focus();
          break;
        default:
          if (index === 0) {
            // go to tab_upnext
            this.$refs.tab_upnext.focus();
          } else {
            // next item
            items[index - 1].focus();
          }
          break;
      }
    },
    goNext(event, index = -1) {
      let from = event.target.id;
      let items = this.getActiveItems();
      switch (from) {
        case 'header_search':
          //go play button, 강제 포커스
          setTimeout(() => {
            document.querySelector('.video-js .vjs-big-play-button').focus();
          });
          break;
        case 'search_field':
          // go search button
          this.$refs.search_button.focus();
          break;
        case 'search_button':
          // go close button
          this.$refs.search_close.focus();
          break;
        case 'search_close':
          // live tab
          this.$refs.tab_live.focus();
          break;
        case 'tab_live':
          // go to tab_upnext
          this.$refs.tab_upnext.focus();
          break;
        case 'tab_upnext':
          if (!items || items.length === 0) {
            this.$refs.search_field.focus();
          } else {
            items[0].focus();
          }
          break;
        case 'info_prev_button':
          // go to info_close_button
          this.$refs.info_close_button.focus();
          break;
        case 'info_close_button':
          // go to info_prev_button
          this.$refs.info_prev_button.focus();
          break;
        default:
          if (items && index === items.length - 1) {
            // go to search_field
            this.$refs.search_field.focus();
          } else {
            // next item
            items[index + 1].focus();
          }
          break;
      }
    },
    startMarquee(wrapBox, titleBox, i = -1) {
      this.$nextTick(() => {
        var targetWrapper = i >= 0 ? this.$refs[wrapBox][i] : this.$refs[wrapBox];
        var targetTitle = i >= 0 ? this.$refs[titleBox][i] : this.$refs[titleBox];
        targetTitle.style.animation = '';
        const targetWrapperWidth = targetWrapper.clientWidth;
        const targetTitleWidth = targetTitle.clientWidth;
        if (targetWrapperWidth < targetTitleWidth) {
          const seconds = targetTitleWidth / targetWrapperWidth;
          targetTitle.style.animation = `marquee ${seconds * 7}s linear infinite`;
        } else {
          targetTitle.style.animation = '';
        }
      });
    },
    stopMarquee(titleBox, i = -1) {
      this.$nextTick(() => {
        var targetTitle = i >= 0 ? this.$refs[titleBox][i] : this.$refs[titleBox];
        targetTitle.style.animation = '';
      });
    },
    listItemFocused(index) {
      this.focusIndex = index;
    },
    listItemBlur() {
      this.focusIndex = -1;
    },
    searchTitleMarquee() {
      this.startMarquee('searchWrapBox', 'searchTitleBox');
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    changeSelectedMenu(index) {
      this.menuheader.selected = index;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.header_height = this.$refs.header?.offsetHeight ?? 0;
      this.isVODStartSupport = localStorage.getItem('VODSupport') == 'Y' ? true : false;
    });
  },
};
</script>

<style lang="scss">
.casting_num {
  color: rgba(255, 255, 255, 0.849);
  padding-right: 0.2rem;
}
.seaching {
  border: 1px solid #bd0838;
}
.seachingBtn {
  background-color: #bd0838 !important;
}
.search_text {
  width: 28em;
}
.search_field,
header {
  font-family: 'LG SUXR', sans-serif;
}
.tabs {
  padding-left: 0px !important;
  font-family: 'LG SUXR', sans-serif;
}
.middle {
  flex: 1;
  text-align: center;
}
.topHeader {
  padding: 0 !important;
}
@media screen and (max-width: 480px) {
  .VODIsEnabled {
    display: flex;
    flex-direction: column;
    .mobile-category {
      margin-top: 0;
    }
  }
}
</style>
