<template>
  <div v-if="shareData.open">
    <div class="pop_dim" style="display:block" v-if="shareData.open" @click="cancelPopup"></div>
    <div class="popup center pop_sharing mobile_change active" v-if="!linkCopied">
      <div class="pop_container" style="z-index:991">
        <div class="pop_content">
          <p>
            {{ getTextdata('text1') }}
          </p>
          <p>
            {{ getTextdata('text2') }}
          </p>
        </div>
        <div class="pop_btn">
          <button
            type="button"
            id="sharePopUp_close"
            ref="sharePopUp_close"
            class="btn type_gray popup_close"
            @click="cancelPopup"
            @keydown.prevent.enter="cancelPopup"
            @keydown.prevent.tab="goNext"
          >
            {{ getTextdata('cancel') }}
          </button>
          <button
            type="button"
            id="sharePopUp_copy"
            ref="sharePopUp_copy"
            class="btn type_red"
            @click="linkCopy"
            @keydown.prevent.enter="copyEntered"
            @keydown.prevent.tab="goNext"
          >
            {{ getTextdata('copy-link') }}
          </button>
        </div>
      </div>
    </div>
    <!-- 링크 복사 완료 -->
    <div id="exampop04" class="popup center pop_sharing mobile_change" v-if="linkCopied">
      <div class="pop_container">
        <div class="pop_content">
          <p>{{ getTextdata('copied') }}</p>
        </div>
        <div class="pop_btn">
          <button
            type="button"
            class="btn type_red popup_close"
            @click="cancelPopup"
            ref="sharePopUp_copy_done"
            @keydown.tab.prevent="goNext"
            @keydown.enter.prevent="cancelPopup"
          >
            {{ getTextdata('ok') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    shareData: Object,
  },
  inject: ['beacon'],
  data() {
    return {
      linkCopied: false,
    };
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.sharePopUp.${whattext}`) ?? '';
      };
    },
  },
  watch: {
    shareData: {
      deep: true,
      handler(newVal) {
        // 스토어 업데이트
        this.$store.dispatch('setPopupStatus', {
          type: 'share',
          open: newVal.open,
        });
        if (newVal.open && newVal.forceFocus) {
          setTimeout(() => {
            this.$refs.sharePopUp_copy.focus();
          }, 30);
        }
      },
    },
  },
  methods: {
    linkCopy() {
      // beacon
      this.beacon.updateFunctionObject('copyButton');

      let deeplinkParam = '';
      if (this.shareData.channelId) {
        deeplinkParam = `&chId=${this.shareData.channelId}`;
      } else if (this.shareData.vodId) {
        deeplinkParam = `&vodId=${this.shareData.vodId}`;
      }
      let targetParam = deeplinkParam ? 'detail' : 'home';
      let params = `target=${targetParam}${deeplinkParam}&caller=linkcopy`;

      const deeplink = window.location.origin + '/?' + btoa(params);
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`${deeplink}`);
      } else {
        // navigator.clipboard 미지원시
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = deeplink;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
      }

      this.linkCopied = true;
    },
    // new methods
    cancelPopup(event) {
      this.linkCopied = false;
      this.$store.dispatch('setPopupStatus', {
        ...this.shareData,
        type: 'share',
        open: false,
        forceFocus: event.type === 'keydown',
      });
    },
    goNext(event) {
      let from = event.target.id;
      switch (from) {
        case 'sharePopUp_close':
          this.$refs.sharePopUp_copy.focus();
          break;
        case 'sharePopUp_copy':
          this.$refs.sharePopUp_close.focus();
          break;
        default:
          // go through (do nothing)
          break;
      }
    },
    copyEntered() {
      this.linkCopy();
      setTimeout(() => {
        this.$refs.sharePopUp_copy_done.focus();
      }, 100);
    },
  },
};
</script>
