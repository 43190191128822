import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    leftNavDrawer: true,
    playSrc: {},
    program: {},
    channelList: {},
    programPopUpIndex: 0,
    country: '',
    runPIP: false,

    advertisement: {
      deviceid: '',
      adid: '',
      lmt: false,
      dns: false,
    },

    agreedTerm: undefined,
    eulaVersions: [],
    eulainfo: [],
    popUpData: { open: false, channelName: '', channelLogoUrl: '', programs: [] }, // 프로그램 정보 팝업
    sharePopUpData: { open: false, channelId: '' }, // 공유팝업
    programInfo: {},
    video_height: null,

    osName: '',
    browserName: '',
    deviceName: '',

    screenMode: '',
    screenSize: {},
    popupStatus: { open: false, type: '' },
  },
  mutations: {
    setDeviceid(state, id) {
      state.advertisement.deviceid = id;
    },
    setAdid(state, id) {
      state.advertisement.adid = id;
    },
    setLmt(state, lmt) {
      state.advertisement.lmt = lmt;
    },
    setDns(state, dns) {
      state.advertisement.dns = dns;
    },
    setAgreed(state, agreedTerm) {
      state.agreedTerm = agreedTerm;
    },
    setEulaVersions(state, versions) {
      state.eulaVersions = versions;
    },
    setEulainfo(state, eula) {
      state.eulainfo = eula;
    },
    setScreenMode(state, mode) {
      state.screenMode = mode;
    },
    setScreenSize(state, size) {
      state.screenSize = size;
    },
    setPopupStatus(state, status) {
      state.popupStatus = status;
    },
    setProgramPopupData(state, data) {
      state.popUpData = data;
    },
    setProgramInfo(state, data) {
      state.programInfo = data;
    },
    setVideoHeight(state, status) {
      state.video_height = status;
    },
  },
  actions: {
    setDeviceInformation({ dispatch }) {
      dispatch('setOsName');
      dispatch('setBrowserName');
      dispatch('setDeviceName');
    },
    setOsName({ state }) {
      const _userAgent = navigator.userAgent;
      if (_userAgent.toLowerCase().includes('like mac')) {
        state.osName = 'ios';
      } else if (_userAgent.toLowerCase().includes('mac')) {
        state.osName = 'mac';
      } else if (_userAgent.toLowerCase().includes('android')) {
        state.osName = 'android';
      } else if (_userAgent.toLowerCase().includes('win')) {
        state.osName = 'windows';
      } else if (_userAgent.toLowerCase().includes('linux')) {
        state.osName = 'linux';
      } else {
        state.osName = 'unknown';
      }
    },
    setBrowserName({ state }) {
      const _userAgent = navigator.userAgent;
      if (_userAgent.toLowerCase().includes('firefox')) {
        state.browserName = 'firefox';
      } else if (_userAgent.toLowerCase().includes('edge')) {
        state.browserName = 'edge';
      } else if (_userAgent.toLowerCase().includes('chrome') || _userAgent.toLowerCase().includes('crios')) {
        state.browserName = 'chrome';
      } else if (_userAgent.toLowerCase().includes('safari')) {
        state.browserName = 'safari';
      } else {
        state.browserName = 'unknown';
      }
    },
    setDeviceName({ state }) {
      const _userAgent = navigator.userAgent;
      if (
        (_userAgent.toLowerCase().includes('android') && _userAgent.toLowerCase().includes('mobile')) ||
        _userAgent.toLowerCase().includes('iphone')
      ) {
        state.deviceName = 'mobile';
      } else if (_userAgent.toLowerCase().includes('android') || _userAgent.toLowerCase().includes('ipad')) {
        state.deviceName = 'tablet';
      } else if (
        _userAgent.toLowerCase().includes('macintosh') &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 0
      ) {
        state.deviceName = 'tablet';
      } else {
        state.deviceName = 'pc';
      }
    },
    async initAdvertisement({ commit, dispatch }) {
      try {
        let id = localStorage.getItem('client_id');
        if (!id) {
          await dispatch('createDeviceid');
        } else {
          commit('setDeviceid', id);
        }

        let adid = localStorage.getItem('adid');
        if (!adid) {
          await dispatch('createAdid');
        } else {
          commit('setAdid', adid);
        }

        let lmt = localStorage.getItem('ad-limit-check') ? true : false;
        commit('setLmt', lmt);

        let dns = localStorage.getItem('do-not-sell-check') ? true : false;
        commit('setDns', dns);
      } catch (e) {
        console.error(e);
      }
      return false;
    },
    createDeviceid({ commit, state }) {
      if (state.advertisement.deviceid) return;
      let id = this.getters.uuidv4;
      localStorage.setItem('client_id', id);
      commit('setDeviceid', id);
    },
    createAdid({ commit }) {
      let id = this.getters.uuidv4;
      localStorage.setItem('adid', id);
      commit('setAdid', id);
    },
    changeLmt({ commit }, lmt) {
      localStorage.setItem('ad-limit-check', lmt);
      commit('setLmt', lmt);
    },
    changeDns({ commit }, dns) {
      localStorage.setItem('do-not-sell-check', dns);
      commit('setDns', dns);
    },
    changeAgreed({ commit }, agreed) {
      localStorage.setItem('termAgreed', agreed ? 'Y' : 'N');
      commit('setAgreed', agreed);
    },
    changeEulaVersions({ commit }, versions) {
      const encoded = window.btoa(JSON.stringify(versions));
      localStorage.setItem('eulaVersions', encoded);
      commit('setEulaVersions', versions);
    },
    changeEulainfo({ commit }, eula) {
      localStorage.setItem('eulainfo', JSON.stringify(eula));
      commit('setEulainfo', eula);
    },
    setScreenMode({ commit }) {
      if (window.innerWidth > window.innerHeight || window.innerWidth > 1240) {
        commit('setScreenMode', 'landscape');
      } else {
        commit('setScreenMode', 'portrait');
      }
    },
    setScreenSize({ commit }) {
      commit('setScreenSize', {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    setPopupStatus({ commit }, status) {
      commit('setPopupStatus', status);
    },
    setProgramPopupData({ commit }, data) {
      commit('setProgramPopupData', data);
    },
    setProgramInfo({ commit }, data) {
      commit('setProgramInfo', data);
    },
    setVideoHeight({ commit }, status) {
      commit('setVideoHeight', status);
    },
  },
  getters: {
    getOsName(state) {
      return state.osName;
    },
    getBrowserName(state) {
      return state.browserName;
    },
    getDeviceName(state) {
      return state.deviceName;
    },
    getDeviceid(state) {
      return state.advertisement.deviceid;
    },
    getAdid(state) {
      return state.advertisement.lmt === true ? '' : state.advertisement.adid;
    },
    getLmt(state) {
      return state.advertisement.lmt;
    },
    getDns(state) {
      return state.advertisement.dns;
    },
    getAgreed(state) {
      return state.agreedTerm;
    },
    getEulaVersions(state) {
      if (!state.eulaVersions || state.eulaVersions.length === 0) {
        // local storage 저장값 가져오기
        let localValue = localStorage.getItem('eulaVersions');
        if (localValue) {
          let parsedValue = JSON.parse(window.atob(localValue));
          if (parsedValue?.length > 0) {
            state.eulaVersions = parsedValue;
            return parsedValue;
          }
        }
        // invalid or empty value in localStorage
        state.eulaVersions = [];
      }
      return state.eulaVersions.length > 0 ? state.eulaVersions : [];
    },
    getEulainfo(state) {
      if (!state.eulainfo || state.eulainfo.length === 0) {
        // local storage 저장값 가져오기
        let localValue = localStorage.getItem('eulainfo');
        if (localValue) {
          let parsedValue = JSON.parse(localValue);
          if (parsedValue?.length > 0) {
            state.eulainfo = parsedValue;
            return parsedValue;
          }
        }
        // invalid or empty value in localStorage
        state.eulainfo = [];
      }
      return state.eulainfo.length > 0 ? state.eulainfo : [];
    },
    getScreenMode(state) {
      return state.screenMode;
    },
    getScreenSize(state) {
      return state.screenSize;
    },
    getPopupStatus(state) {
      return state.popupStatus;
    },
    getProgramPopupData(state) {
      return state.popUpData;
    },
    getProgramInfo(state) {
      return state.programInfo;
    },
    getPopupOpened(state) {
      return state.popupStatus?.open || state.popUpData?.open || !state.agreedTerm;
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    getVideoHeight(state) {
      return state.video_height;
    },
  },
  modules: {},
});
