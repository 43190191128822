import SI from '@/apis/service_interface';
import { version } from '../../package.json';

export default {
  data() {
    return {
      enabled: false,
      configs: {},

      beaconInterval: undefined,
      browserSessionId: undefined,
      startedTime: 0,
      durationTime: 0,
      appPauseFlag: false,
      launchByLink: false,
      callerId: '',

      beaconUTCTimestamp: 0,
      beaconStartedMarkTime: 0,

      contentListArray: [],
      detailListArray: [],
      functionObject: {},
    };
  },

  methods: {
    async initBeaconInfo() {
      this.debugConsoleLog(`[beacon] init`);

      this.configs = await SI.getBeaconConfig();
      this.debugConsoleLog(`[beacon] beaconConfig = ${JSON.stringify(this.configs)}`);

      if (this.configs && this.configs.enableSwitch === 'on') {
        this.enabled = true;

        this.beaconUTCTimestamp = this.configs.timestamp;
        this.beaconStartedMarkTime = performance.now();

        this.startedTime = this.getUTCTimeStamp();
        this.debugConsoleLog(`[beacon] launched time = ${this.startedTime}`);

        this.initialFunctionObject();

        this.changeBrowserSessionId();
        this.startBeaconInterval();
      }
    },

    initialContentInfomation(_lastProgram) {
      this.startedTime = this.getUTCTimeStamp();
      this.durationTime = 0;
      this.contentListArray.length = 0;
      this.detailListArray.length = 0;
      this.initialFunctionObject();

      if (_lastProgram) {
        this.debugConsoleLog(`[beacon] save last program after sending program beacon`);
        _lastProgram.currentTime = this.getUTCTimeStamp();
        _lastProgram.duration = 0;
        this.contentListArray.push(_lastProgram);
      }
    },

    initialFunctionObject() {
      this.functionObject = {
        shareButton: 0,
        search: 0,
        footerLgchannels: 0,
        footerTerms: 0,
        footerPolicy: 0,
        footerDoNotSell: 0,
        footerSettings: 0,
        footerSupport: 0,
        copyButton: 0,
        launchAppStore: 0,
      };
    },

    startBeaconInterval() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      if (this.beaconInterval !== undefined) {
        this.stopBeaconInterval();
      }

      this.debugConsoleLog(`[beacon] beacon timer has started`);
      this.beaconInterval = setInterval(this.sendBeacon, this.configs.sendInterval * 1000);
    },

    stopBeaconInterval() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      if (this.beaconInterval !== undefined) {
        clearInterval(this.beaconInterval);
        this.beaconInterval = undefined;
        this.debugConsoleLog(`[beacon] beacon timer has stopped`);
      }
    },

    appResume() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      if (this.appPauseFlag) {
        this.appPauseFlag = false;
        this.startedTime = this.getUTCTimeStamp();
        this.debugConsoleLog(`[beacon] home app resume, this.startedTime = ${this.startedTime}`);
        this.changeBrowserSessionId();
        this.sendBeacon();
      }
    },

    appPause() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      this.sendBeacon();
      this.appPauseFlag = true;
      this.durationTime += this.getUTCTimeStamp() - this.startedTime;
      this.debugConsoleLog(`[beacon] home app pause, this.durationTime = ${this.durationTime}`);
    },

    debugConsoleLog(msg) {
      let _isShowConsoleLog = localStorage.getItem('isShowConsoleLog');

      if (_isShowConsoleLog === 'true') {
        console.log(msg);
      }
    },

    setLaunchByLink(callerId, flag = false) {
      this.launchByLink = flag;
      this.callerId = callerId;
    },

    changeBrowserSessionId() {
      this.browserSessionId = this.getRandomSessionId();
      this.debugConsoleLog(`[beacon] home app, browserSessionId = ${this.browserSessionId}`);
    },

    getRandomSessionId() {
      const crypto = window.crypto || window.msCrypto;
      const hex = '0123456789abcdef';
      let output = '';
      for (let i = 0; i < 64; ++i) {
        output += hex.charAt(crypto.getRandomValues(new Uint32Array(1))[0] % hex.length);
      }
      return output;
    },

    // unit : second
    getUTCTimeStamp() {
      return Math.floor(this.beaconUTCTimestamp + (performance.now() - this.beaconStartedMarkTime) / 1000);
    },

    async updateLastContentDuration() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      if (this.contentListArray.length > 0) {
        let _lastProgram = this.contentListArray[this.contentListArray.length - 1];

        if (_lastProgram.duration === 0) {
          let _duration = Math.abs(this.getUTCTimeStamp() - _lastProgram.currentTime);
          if (_duration.duration < 1) {
            this.debugConsoleLog(`[beacon] program beacon duration is 0, so ignore`);
            this.contentListArray.pop();
          } else {
            if (_duration > this.configs.sendInterval) {
              this.debugConsoleLog(
                `[beacon] program beacon duration(${_duration})(s) is greater than beacon send interval, so change to beacon send interval`
              );
              _lastProgram.duration = this.configs.sendInterval;
            } else {
              this.debugConsoleLog(`[beacon] program beacon duration ${_duration}(s) is updated`);
              _lastProgram.duration = _duration;
            }
          }
        }
      }
    },

    saveContent(programInfo, channelInfo) {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      let _content = {
        categoryName: channelInfo?.categoryName || '',
        categoryCode: channelInfo?.categoryCode || '',
        channelId: channelInfo?.channelId || '',
        channelName: channelInfo?.channelName || '',
        channelGenre: channelInfo?.channelGenreName || '',
        providerId: channelInfo?.providerId || '',
        channelSessionId: channelInfo?.channelSessionId || '',
        programId: programInfo?.programId || '',
        programTitle: programInfo?.programTitle || '',
        programGenre: programInfo?.engGenreName || '',
        contentSessionId: programInfo?.contentSessionId || '',
        currentTime: this.getUTCTimeStamp(),
        duration: 0,
      };

      this.contentListArray.push(_content);
      this.debugConsoleLog(`[beacon] save content = ${JSON.stringify(this.contentListArray)}`);
    },
    updateDetailItem(itemIndex, nextProgram) {
      let item = this.detailListArray[itemIndex];
      if (nextProgram) {
        let _programIndex = item.nextProgram.findIndex(
          nextProgramItem => nextProgramItem.programId === nextProgram.programId
        );

        if (_programIndex > -1) {
          item.nextProgram[_programIndex].currentTime = this.getUTCTimeStamp();
          item.nextProgram[_programIndex].clickCount++;
        } else {
          let _nextProgramItem = {};
          _nextProgramItem.programId = nextProgram.programId;
          _nextProgramItem.programTitle = nextProgram.programTitle;
          _nextProgramItem.currentTime = this.getUTCTimeStamp();
          _nextProgramItem.clickCount = 1;
          item.nextProgram.push(_nextProgramItem);
        }
      } else {
        item.currentTime = this.getUTCTimeStamp();
        item.clickCount++;
      }
    },
    addNewDetailItem(programInfo, channelInfo, nextProgram) {
      let _detail = {
        categoryName: channelInfo?.categoryName || '',
        categoryCode: channelInfo?.categoryCode || '',
        channelId: channelInfo?.channelId || '',
        channelName: channelInfo?.channelName || '',
        providerId: channelInfo?.providerId || '',
        programId: programInfo?.programId || '',
        programTitle: programInfo?.programTitle || '',
        channelSessionId: channelInfo?.channelSessionId || '',
        contentSessionId: programInfo?.contentSessionId || '',
        currentTime: this.getUTCTimeStamp(),
        clickCount: 1,
        nextProgram: [],
      };

      if (nextProgram) {
        let _nextProgramItem = {};
        _nextProgramItem.programId = nextProgram.programId;
        _nextProgramItem.programTitle = nextProgram.programTitle;
        _nextProgramItem.currentTime = this.getUTCTimeStamp();
        _nextProgramItem.clickCount = 1;
        _detail.nextProgram.push(_nextProgramItem);
      }

      this.detailListArray.push(_detail);
    },
    saveDetail(programInfo, channelInfo, nextProgram = undefined) {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      let _detailListIndex = this.detailListArray.findIndex(
        detailItem => detailItem.channelId === channelInfo?.channelId
      );

      if (_detailListIndex > -1) {
        this.updateDetailItem(_detailListIndex, nextProgram);
      } else {
        this.addNewDetailItem(programInfo, channelInfo, nextProgram);
      }
      this.debugConsoleLog(`[beacon] save detail = ${JSON.stringify(this.detailListArray)}`);
    },

    updateFunctionObject(type) {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      switch (type) {
        case 'share':
          this.functionObject.shareButton++;
          break;

        case 'search':
          this.functionObject.search++;
          break;

        case 'channelInfo':
          this.functionObject.footerLgchannels++;
          break;

        case 'termsOfUse':
          this.functionObject.footerTerms++;
          break;

        case 'privacyPolicy':
          this.functionObject.footerPolicy++;
          break;

        case 'notSell':
          this.functionObject.footerDoNotSell++;
          break;

        case 'settings':
          this.functionObject.footerSettings++;
          break;

        case 'support':
          this.functionObject.footerSupport++;
          break;

        case 'copyButton':
          this.functionObject.copyButton++;
          break;

        case 'launchAppStore':
          this.functionObject.launchAppStore++;
          break;
      }

      this.debugConsoleLog(`[beacon] updateFunctionObject = ${JSON.stringify(this.functionObject)}`);
    },

    async sendBeacon() {
      if (!this.enabled) {
        this.debugConsoleLog(`[beacon] beacon is disable`);
        return;
      }

      let _lastProgram = undefined;
      if (this.contentListArray.length > 0) {
        _lastProgram = JSON.parse(JSON.stringify(this.contentListArray[this.contentListArray.length - 1]));

        await this.updateLastContentDuration();
      }

      this.durationTime += this.getUTCTimeStamp() - this.startedTime;

      if (this.durationTime > this.configs.sendInterval) {
        this.debugConsoleLog(
          `[beacon] send, durationTime(${this.durationTime}) is greater than beacon send interval, so change to beacon send interval`
        );
        this.durationTime = this.configs.sendInterval;
      } else {
        this.debugConsoleLog(`[beacon] send, durationTime(${this.durationTime})`);
      }

      // channel duration range check
      const _contentListArray = this.contentListArray.filter(content => {
        return content.duration >= 1 && content.duration <= this.configs.sendInterval;
      });

      const data = {
        osName: this.$store.getters.getOsName,
        country: this.$store.state.country,
        browserName: this.$store.getters.getBrowserName,
        deviceName: this.$store.getters.getDeviceName,
        userAgent: navigator.userAgent,
        currentTime: this.getUTCTimeStamp(),
        appVersion: version,
        browserId: this.$store.getters.getDeviceid,
        duration: this.durationTime,
        browserSessionId: this.browserSessionId,
        launchByLink: this.launchByLink,
        callerId: this.callerId,
        content: _contentListArray,
        detail: this.detailListArray,
        function: this.functionObject,
      };

      const result = SI.sendWebBeacon(JSON.parse(JSON.stringify(data)));
      this.debugConsoleLog(`[beacon] web beacon send data = ${JSON.stringify(data)}`);
      result.then(res => {
        this.debugConsoleLog(`[beacon] web beacon send result = ${res}`);
      });

      this.initialContentInfomation(_lastProgram);
    },
  },

  provide() {
    return {
      beacon: this,
    };
  },
};
