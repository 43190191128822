<template>
  <div style="color:white" v-if="popUpData.open">
    <div class="pop_dim" style="display:block" @click="closePopup"></div>
    <div
      id="exampop08"
      class="popup center pop_settings mobile_change active"
      style="color:white; z-index:991"
      v-show="popUpData.step === 1"
    >
      <div class="pop_container no_focus_outlined" id="setting_container" tabindex="0">
        <div class="pop_header standard">
          <h2>{{ getTextdata('title') }}</h2>
        </div>
        <div class="pop_content" style="display:grid">
          <div class="switch_wrap">
            <strong class="switch_guide">{{ getTextdata('label') }}</strong>
          </div>
          <div class="inner_info">
            <span
              @click="changeAd"
              @keydown.prevent.space.enter="changeAd"
              @keydown.prevent.shift.tab="goPrev"
              @keydown.prevent.tab.exact="goNext"
            >
              <label for="setting_switch">
                <strong class="switch_guide">{{ getTextdata('limit_ad') }}</strong>
              </label>
              <div tabindex="0" ref="setting_switch" class="switch" id="setting_switch">
                <input type="checkbox" :checked="checked" />
                <span class="slider"></span>
              </div>
            </span>
          </div>
          <p class="inner_info">{{ getTextdata('content') }}</p>
          <div class="side_item">
            <a
              id="setting_popup_leave_open"
              ref="setting_popup_leave_open"
              tabindex="2"
              role="button"
              style="color:white"
              @click="openLeaveSettings"
              @keydown.prevent.enter="openLeaveSettings"
              @keydown.prevent.shift.tab="goPrev"
              @keydown.prevent.tab.exact="goNext"
              >{{ getTextdata('openLeave') }}</a
            >
          </div>
        </div>
        <button
          type="button"
          tabindex="3"
          class="btn btn_close popup_close"
          ref="setting_popup_close"
          id="setting_popup_close"
          @click="closePopup"
          @keydown.prevent.enter="closePopup"
          @keydown.prevent.shift.tab="goPrev"
          @keydown.prevent.tab.exact="goNext"
        >
          {{ getTextdata('close') }}
        </button>
      </div>
    </div>
    <!-- Popup : Leave LG Channels -->
    <div
      id="exampop09"
      class="popup center pop_leave_channels mobile_change active"
      style="z-index:991"
      v-show="popUpData.step === 2"
    >
      <div class="pop_container">
        <div class="pop_header standard">
          <h2>{{ getTextdata('leaveLGChannels') }}</h2>
        </div>
        <div class="pop_content">
          <p>{{ getTextdata('leaveContent') }}</p>
        </div>
        <div class="pop_btn">
          <button
            type="button"
            class="btn"
            id="setting_popup_stay"
            ref="setting_popup_stay"
            @click="backToSettings"
            @keydown.prevent.enter="backToSettings"
            @keydown.prevent.shift.tab="goPrev"
            @keydown.prevent.tab.exact="goNext"
          >
            <span>{{ getTextdata('stay') }}</span>
          </button>
          <button
            type="button"
            class="btn popup_close"
            id="setting_popup_leave"
            ref="setting_popup_leave"
            @click="leaveService"
            @keydown.prevent.enter="leaveService"
            @keydown.prevent.shift.tab="goPrev"
            @keydown.prevent.tab.exact="goNext"
          >
            <span>{{ getTextdata('leave') }}</span>
          </button>
        </div>
        <button
          type="button"
          class="btn btn_close popup_close"
          id="setting_close_leave"
          ref="setting_close_leave"
          @click="closePopup"
          @keydown.prevent.enter="closePopup"
          @keydown.prevent.shift.tab="goPrev"
          @keydown.prevent.tab.exact="goNext"
        >
          {{ getTextdata('close') }}
        </button>
      </div>
    </div>
  </div>
  <!-- // Popup : Leave LG Channels -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    popUpData: Object,
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    popUpData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$store.dispatch('setPopupStatus', {
          open: newVal.open,
          type: newVal.type,
        });
        if (newVal.forceFocus) {
          setTimeout(() => {
            this.$refs.setting_popup_close.focus();
          }, 30);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.settingsPopUp.${whattext}`) ?? '';
      };
    },
  },
  mounted() {
    let checkValue = this.$store.getters.getLmt;
    if (checkValue) {
      if (checkValue === true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    } else {
      this.checked = false;
    }
  },
  methods: {
    changeAd() {
      this.checked = !this.checked;
      this.$store.dispatch('changeLmt', this.checked);
    },
    leaveService() {
      localStorage.clear();
      sessionStorage.clear();
      let cookies = document.cookie.split(';');
      for (let myCookie of cookies) {
        let pos = myCookie.indexOf('=');
        let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
        document.cookie = name + '=; Max-Age=0; path=/; domain=' + location.hostname;
      }
      localStorage.setItem('client_id', this.$store.getters.getDeviceid);
      window.location.reload();
    },
    openLeaveSettings() {
      this.popUpData.step = 2;
      setTimeout(() => {
        this.$refs.setting_popup_stay.focus();
      }, 100);
    },
    closePopup(event) {
      this.$store.dispatch('setPopupStatus', {
        ...this.popUpData,
        open: false,
        forceFocus: event.type === 'keydown',
      });
    },
    // new method
    goNext(event) {
      let from = event.target.id;
      switch (from) {
        case 'setting_popup_close':
          // switch
          this.$refs.setting_switch.focus();
          break;
        case 'setting_switch':
          // leave 버튼
          this.$refs.setting_popup_leave_open.focus();
          break;
        case 'setting_popup_leave_open':
          // close 버튼
          this.$refs.setting_popup_close.focus();
          break;
        case 'setting_popup_stay':
          // leave
          this.$refs.setting_popup_leave.focus();
          break;
        case 'setting_popup_leave':
          // close
          this.$refs.setting_close_leave.focus();
          break;
        case 'setting_close_leave':
          // stay
          this.$refs.setting_popup_stay.focus();
          break;
      }
    },
    goPrev(event) {
      let from = event.target.id;
      switch (from) {
        case 'setting_popup_close':
          // leave 버튼
          this.$refs.setting_popup_leave_open.focus();
          break;
        case 'setting_switch':
          // close 버튼
          this.$refs.setting_popup_close.focus();
          break;
        case 'setting_popup_leave_open':
          // switch
          this.$refs.setting_switch.focus();
          break;
        case 'setting_popup_stay':
          // close
          this.$refs.setting_close_leave.focus();
          break;
        case 'setting_popup_leave':
          // stay
          this.$refs.setting_popup_stay.focus();
          break;
        case 'setting_close_leave':
          // leave
          this.$refs.setting_popup_leave.focus();
          break;
      }
    },
    backToSettings() {
      this.popUpData.step = 1;
      setTimeout(() => {
        this.$refs.setting_popup_leave_open.focus();
      }, 100);
    },
  },
};
</script>
