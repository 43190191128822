<template>
  <v-tabs
    touch
    id="categoryTab"
    class="category-tab"
    dark
    background-color="black"
    slider-size="2"
    center-active
    show-arrows
    @touchstart="scrollHide"
    @touchend="scrollAble"
  >
    <v-tabs-slider color="#bd0838"></v-tabs-slider>
    <v-tab
      class="tabFocus"
      tabindex="0"
      touch
      :ripple="false"
      v-for="(tab, i) in tabs.categories"
      :key="i"
      ref="tabRef"
      @click="changeTab(tab, i)"
      @focus="scrollFirst(i)"
      @touchstart="scrollHide"
      @touchmove="iosDevice ? scrollHide : ''"
      @touchend="scrollAble"
      ><span :style="tabItemStyle(i)" class="category-no-value">{{ tab.categoryName }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    tabs: Object,
    nextTab: Object,
    forceFocus: Object,
  },
  data() {
    return {
      iosDevice: false,
      tabPos: {},
    };
  },
  created() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.iosDevice = true;
    }
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
    }),
    tabItemStyle() {
      return index => {
        if (this.tabs.selected.index === index) {
          return {
            fontWeight: 500,
            color: 'white',
          };
        } else {
          return {};
        }
      };
    },
  },
  watch: {
    nextTab: {
      deep: true,
      handler(newVal) {
        if (newVal.index < 0) return;
        this.$nextTick(() => {
          this.$refs.tabRef[newVal.index].$el.click();
        });
      },
    },
    screenSize: {
      deep: true,
      handler() {
        this.tabPositionChanged();
      },
    },
    forceFocus: {
      deep: true,
      handler(newVal) {
        if (newVal.focus) {
          this.$refs.tabRef[0].focus();
        }
      },
    },
  },
  mounted() {
    // document.getElementsByClassName('v-slide-group__wrapper')[0].addEventListener('touchmove', () => {
    //   let container = document.getElementsByClassName('v-slide-group__content v-tabs-bar__content')[0];
    //   let xvalue = container.style.transform.slice(11, container.style.transform.length - 3);
    //   let iconDiv = document.getElementsByClassName('v-slide-group__prev')[0];

    //   if (xvalue >= 0) {
    //     iconDiv.classList.add('v-slide-group__prev--disabled');
    //   } else {
    //     iconDiv.classList.remove('v-slide-group__prev--disabled');
    //   }
    // });
    this.tabPositionChanged();
  },

  methods: {
    changeTab(tab, i) {
      this.tabs.selected = {
        index: i,
        timestamp: new Date().getTime(),
      };
      this.tabs.nowCategory = [tab];
    },
    scrollFirst(i) {
      if (i === 0) {
        for (let index = 0; index < this.tabs.categories.length / 4; index++) {
          document.getElementsByClassName('v-slide-group__prev')[0].click();
        }
      }
    },
    scrollHide() {
      document.documentElement.style.setProperty('overflow', 'hidden', 'important');
    },
    scrollAble() {
      document.documentElement.style.setProperty('overflow', 'auto', 'important');
    },
    tabPositionChanged() {
      this.tabPos = this.$refs.tabRef[0].$el.getBoundingClientRect();
      this.$emit('tabposition', this.tabPos);
    },
  },
};
</script>

<style lang="scss">
.v-tabs .v-tabs-bar .v-slide-group__prev,
.v-tabs .v-tabs-bar .v-slide-group__prev--disabled {
  position: absolute;
  left: -1.8rem;
  z-index: 2;
  .v-icon::before {
    background-image: url('../assets/images/ico/ic_back@3x.png');
    background-size: cover;
    width: 1.8rem;
    height: 1.8rem;
    color: transparent;
  }
}
.v-tabs .v-tabs-bar .v-slide-group__next,
.v-tabs .v-tabs-bar .v-slide-group__next--disabled {
  position: absolute;
  right: -1.8rem;
  z-index: 2;
  .v-icon::before {
    background-image: url('../assets/images/ico/ic_next@3x.png');
    background-size: cover;
    width: 1.8rem;
    height: 1.8rem;
    color: transparent;
  }
}

.v-tabs .v-tabs-bar .v-slide-group__prev,
.v-tabs .v-tabs-bar .v-slide-group__next,
.v-tabs .v-tabs-bar .v-slide-group__prev--disabled,
.v-tabs .v-tabs-bar .v-slide-group__next--disabled {
  opacity: 1;
  transition: all 0.3s;
  height: 100%;
  min-width: 0px !important;
}

.v-tabs:hover .v-tabs-bar .v-slide-group__prev {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.v-tabs:hover .v-tabs-bar .v-slide-group__next {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.v-tabs .v-tabs-bar .v-slide-group__prev--disabled,
.v-tabs .v-tabs-bar .v-slide-group__next--disabled {
  pointer-events: auto !important;
  cursor: default !important;
  display: none;
}
.v-tabs:hover .v-tabs-bar .v-slide-group__prev,
.v-tabs:hover .v-tabs-bar .v-slide-group__next {
  opacity: 1;
  transition: all 0.3s;
}

.v-tab .category-no-value {
  border-radius: 19px;
  padding: 0px;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #808080;
}
.theme--dark.v-tabs .v-tab:hover::before,
.theme--dark.v-tabs .v-tab::before {
  opacity: 0 !important;
}
.v-slide-group__content {
  height: 34px;
  font-family: 'LG SUXR', sans-serif !important;
}
.v-tab {
  min-width: 0px !important;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0px !important;
  font-weight: normal !important;
  letter-spacing: 0rem !important;
}
.category-tab {
  .v-item-group {
    height: fit-content;
    .v-slide-group__wrapper {
      height: fit-content;
    }
  }
}
</style>
