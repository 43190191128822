<template>
  <div v-if="notSell.open">
    <div class="pop_dim" style="display:block" @click="closePopup"></div>
    <div id="exampop07" class="popup center pop_warning mobile_change active" style="color:white; z-index:991">
      <div tabindex="0" class="pop_container no_focus_outlined" id="do_not_sell_container">
        <div class="pop_header standard">
          <h2>{{ getTextdata('title') }}</h2>
        </div>
        <div class="pop_content">
          <p>
            {{ getTextdata('content') }}
          </p>
          <div class="switch_wrap">
            <span class="switch_guide">{{ getTextdata('label') }} </span>
            <label
              tabindex="0"
              class="switch"
              id="do_not_sell_switch"
              ref="do_not_sell_switch"
              @click="changeDonot()"
              @keydown.prevent.space.enter="changeDonot"
              @keydown.prevent.tab="tabPressed"
            >
              <input type="checkbox" :checked="checked" class="checked" />
              <span class="slider" />
              <!-- on/off -->
              <span class="switch_label" />
            </label>
          </div>
        </div>
        <button
          type="button"
          class="btn btn_close popup_close"
          ref="do_not_sell_close"
          id="do_not_sell_close"
          @click="closePopup"
          @keydown.prevent.enter="closePopup"
          @keydown.prevent.tab="tabPressed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    notSell: Object,
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    notSell: {
      deep: true,
      handler(newVal) {
        this.$store.dispatch('setPopupStatus', {
          open: newVal.open,
          type: 'notSell',
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.notSellPopUp.${whattext}`) ?? '';
      };
    },
  },
  mounted() {
    let checkValue = this.$store.getters.getDns;
    if (checkValue) {
      if (checkValue === true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    } else {
      this.checked = false;
    }
  },
  methods: {
    changeDonot() {
      this.checked = !this.checked;
      this.$store.dispatch('changeDns', this.checked);
    },
    closePopup(event) {
      this.notSell.open = false;
      if (event.type === 'keydown') {
        document.querySelector('#footer_notSell').focus();
      }
    },
    tabPressed(event) {
      let nextTarget = event.target.id === 'do_not_sell_switch' ? 'do_not_sell_close' : 'do_not_sell_switch';
      this.$refs[nextTarget].focus();
    },
  },
};
</script>

<style lang="scss">
.switch:focus-visible {
  border: 1px solid white !important;
}
</style>
