import axiosInstance from '@/apis/axiosInstance';
import epgList from '../../public/api/epg_list3.json';
import eulaInfo from '../../public/api/eulainfo.json';
import store from '../store/index';

class ServiceInterface {
  constructor(_mock = false) {
    if (instance) return instance;

    console.log(`[ServiceInterface] created (mock-up mode: ${_mock})`);
    this.name = 'service_interface';
    this.version = '1.0.0';
    this.mock = _mock;

    instance = this;
    this.initialized = false;
    this.headers = {};
    this.init();
  }

  async ensureInitialized() {
    if (this.initialized) return;

    for (let i = 1; i < 100; i++) {
      await new Promise(resolve => setTimeout(resolve, 100 * i)).then(() => foo());
      if (this.initialized) return;
    }

    function foo() {
      // This is intentional
    }
  }

  async processInitData(res) {
    this.headers['X-Device-Country'] = res.data.country;
    this.headers['X-Device-Language'] = res.data.defaultLang;
    // beacon base url
    this.beaconBaseUrl = res.data.beaconUrl;
    this.headers['X-Device-Type'] = 'WEB';

    this.initialized = true;
    // 약관 재동의 필요여부 확인
    let isEulaNeed =
      termAgreed !== 'Y' ||
      !eulaVersions ||
      !eulainfo ||
      eulaVersions.length == 0 ||
      eulainfo.length == 0 ||
      (await this.isEulaUpdated(res.data.eulaVersions));
    store.dispatch('changeAgreed', !isEulaNeed);
    return res.data;
  }

  init() {
    if (mock) {
      const data = JSON.parse(
        '{"data":{"country":"US","lang":["en","es","fr-CA"],"defaultLang":"en","eulaVersions":[{"type":"termsOfUse","version":"20220504-105739","lastUpdated":"2022-05-11 07:20:13"},{"type":"privacyPolicy","version":"20220504-174245","lastUpdated":"2022-05-11 07:20:13"}]}}'
      );
      return this.processInitData(data);
    }

    if (this.initialized) return;

    try {
      let request = axiosInstance();

      request({ method: 'GET', url: '/init' })
        .then(async res => {
          return await this.processInitData(res);
        })
        .catch(e => {
          let code = e.response?.status ? `1${e.response.status}` : 100;
          this.updateErrorStatus(code);
        });
    } catch (e) {
      console.error('[service_interface] failed to init()', e);
      let code = e.response?.status ? `1${e.response.status}` : 100;
      this.updateErrorStatus(code);
      return false;
    }
  }

  async isEulaUpdated(fromServer) {
    try {
      let fromLocal = JSON.parse(window.atob(eulaVersions));
      if (!fromLocal) return true;
      if (fromLocal.length !== fromServer.length) return true;

      let needed = false;
      let minor_changes = false;

      fromServer.forEach(async srv_eula => {
        let loc_eula = fromLocal.find(eula => eula.type === srv_eula.type);
        if (!loc_eula) needed = true;
        else if (srv_eula.version !== loc_eula.version) {
          // 버전 업데이트 -> 해당항목 체크 해제 후 재동의
          loc_eula.checked = false;
          needed = true;
        } else if (srv_eula.lastUpdated !== loc_eula.lastUpdated) {
          // 버전유지, 내용만 수정
          minor_changes = true;
        }
      });
      store.dispatch('changeEulaVersions', fromLocal);
      if (!needed && minor_changes) {
        // 재동의 없이 업데이트
        await this.eulainfo();
        console.log('[service_interface] eulainfo minor changes done');
      }
      // 재동의 필요여부 리턴
      return needed;
    } catch (e) {
      // 저장된 data format 에러 -> 리셋 후 약관 재동의
      console.log('[service_interface] failed to check isEulaUpdated. Reset local eulaVersion data', e);
      store.dispatch('changeEulaVersions', []);
      return true;
    }
  }

  async schedulelist(channelId = null) {
    if (mock) {
      return {
        result: true,
        value: epgList,
      };
    }

    try {
      await this.ensureInitialized();
      let request = axiosInstance();
      let requestUrl = channelId ? '/schedulelist?channelId=' + channelId : '/schedulelist';
      let res = await request({ method: 'GET', url: requestUrl, headers: this.headers });
      return {
        result: true,
        value: res.data,
      };
    } catch (e) {
      console.error('[service_interface] failed to get schedulelist', e);
      let code = e.response?.status ? `2${e.response.status}` : 200;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  async ondemandlist(params) {
    try {
      let request, requestUrl, res;
      if (params) {
        request = axiosInstance();
        requestUrl = `/dashboard`;
        res = await request({ method: 'GET', url: requestUrl, params: params });
      } else {
        request = axiosInstance('local');
        requestUrl = '/api/ondemand.json';
        res = await request({ method: 'GET', url: requestUrl });
      }
      return {
        result: true,
        value: res.data,
      };
    } catch (e) {
      console.error('[service_interface] failed to get schedulelist', e);
      let code = e.response?.status ? `2${e.response.status}` : 200;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  async vodDetails(params) {
    try {
      let request, requestUrl, res;
      if (params) {
        request = axiosInstance();
        requestUrl = `/vod/detail`;
        res = await request({ method: 'GET', url: requestUrl, params: params });
      } else {
        request = axiosInstance('local');
        requestUrl = '/api/vodDetails.json';
        res = await request({ method: 'GET', url: requestUrl });
      }
      return {
        result: true,
        value: res.data,
      };
    } catch (e) {
      console.error('[service_interface] failed to get schedulelist', e);
      let code = e.response?.status ? `2${e.response.status}` : 200;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  // server api exist -> local api is mixed
  async seasonDetails(params) {
    try {
      let request, requestUrl, res;
      if (params) {
        request = axiosInstance();
        requestUrl = `/season/detail`;
        res = await request({ method: 'GET', url: requestUrl, params: params });
      } else {
        let request = axiosInstance('local');
        let requestUrl = `/api/season.json`;
        res = await request({ method: 'GET', url: requestUrl });
      }
      return {
        result: true,
        value: res.data,
      };
    } catch (e) {
      console.error('[service_interface] failed to get schedulelist', e);
      let code = e.response?.status ? `2${e.response.status}` : 200;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  // local api exist -> server api is not enabled
  async suggestionlist() {
    try {
      let request = axiosInstance('local');
      let requestUrl = '/api/suggestion.json';
      let res = await request({ method: 'GET', url: requestUrl });
      return {
        result: true,
        value: res.data,
      };
    } catch (e) {
      console.error('[service_interface] failed to get schedulelist', e);
      let code = e.response?.status ? `2${e.response.status}` : 200;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  async eulainfo() {
    if (mock) return eulaInfo.termsList;

    try {
      await this.ensureInitialized();
      let request = axiosInstance();
      let res = await request({ method: 'GET', url: '/eulainfo', headers: this.headers });
      // 기존 동의상태 적용 (checked)
      let savedVersions = store.getters.getEulaVersions;
      res.data.termsList.forEach(term => {
        let saved = savedVersions.find(s => s.type === term.type);
        term.checked = (saved && saved.checked) || false;
      });
      // eulainfo 저장
      store.dispatch('changeEulainfo', res.data.termsList);
      return {
        result: true,
        value: res.data.termsList,
      };
    } catch (e) {
      console.error('[service_interface] failed to get eulainfo', e);
      let code = e.response?.status ? `3${e.response.status}` : 300;
      this.updateErrorStatus(code);
      return {
        result: false,
        error: { code: code },
      };
    }
  }

  updateErrorStatus(code) {
    store.dispatch('setPopupStatus', {
      type: 'error',
      open: true,
      code: code,
    });
  }

  // beacon
  async getBeaconConfig() {
    try {
      let request = axiosInstance('beacon', this.beaconBaseUrl);
      let requestUrl = 'configs';
      let res = await request({ method: 'GET', url: requestUrl, headers: this.headers });
      return res.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async sendWebBeacon(payload) {
    try {
      let request = axiosInstance('beacon', this.beaconBaseUrl);
      let requestUrl = 'web';
      let res = await request({ method: 'POST', url: requestUrl, headers: this.headers, data: { ...payload } });
      return res.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async getCountry() {
    try {
      await this.ensureInitialized();
      return this.headers['X-Device-Country'];
    } catch (e) {
      console.log(e);
    }
  }

  async getLanguage() {
    try {
      await this.ensureInitialized();
      return this.headers['X-Device-Language'];
    } catch (e) {
      console.log(e);
    }
  }
}

let instance = null;
let mock = localStorage.getItem('mockup') === 'Y';
let eulaVersions = localStorage.getItem('eulaVersions');
let termAgreed = localStorage.getItem('termAgreed');
let eulainfo = localStorage.getItem('eulainfo');

instance = new ServiceInterface(mock);

export default instance;
