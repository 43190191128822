<template>
  <div>
    <footer class="footer" ref="footer">
      <div class="terms" v-for="(item, i) in footerItems" :key="i">
        <a
          role="button"
          tabindex="0"
          @click="openTermPopup($event, item.type)"
          @keydown.prevent.enter="openTermPopup($event, item.type)"
          :id="`footer_${item.type}`"
          :ref="`footer_${item.type}`"
          >{{ item.title }}
        </a>
      </div>
      <termDialog v-if="termPopup.open" :popUpData="termPopup" />
      <settingsPopUp v-if="settings.open" :popUpData="settings" />
      <supportPopUp v-if="supportPopUpData.open" :popUpData="supportPopUpData" />
    </footer>
  </div>
</template>

<script>
import termDialog from './termDialog.vue';
import settingsPopUp from './settingsPopUp.vue';
import { mapGetters } from 'vuex';
import SI from '@/apis/service_interface';
import supportPopUp from './supportPopUp.vue';
export default {
  components: {
    termDialog,
    settingsPopUp,
    supportPopUp,
  },
  inject: ['beacon'],
  data() {
    return {
      termPopup: {
        //팝업 데이터
        open: false,
        type: '',
        description: '',
      },
      notSell: {
        open: false,
      },
      settings: {
        open: false,
        step: 1,
      },
      footerItems: [],
      errorPopUpData: {
        open: false,
        code: 0,
      },
      supportPopUpData: {
        open: false,
      },

      initialized: false,
      scroll: 0,
      footer_height: 0,
    };
  },
  computed: {
    ...mapGetters({
      eulaVersions: 'getEulaVersions',
      eulainfo: 'getEulainfo',
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      popupStatus: 'getPopupStatus',
    }),
  },
  watch: {
    eulaVersions: {
      deep: true,
      async handler(newVersions) {
        if (newVersions) {
          await this.initialize();
        }
      },
    },
    termPopup: {
      deep: true,
      handler(newVal) {
        if (this.screenMode === 'portrait') {
          if (newVal.open) {
            this.scroll = window.scrollY;
          } else {
            document.documentElement.style.setProperty('top', '', 'important');
            window.scrollTo(0, this.scroll);
          }
        }
      },
    },
    screenSize: {
      handler() {
        this.footer_height = this.$refs.footer?.offsetHeight ?? 0;
      },
    },
    footer_height: {
      handler(newVal) {
        this.$emit('offsetHeight', newVal);
      },
    },
    popupStatus: {
      deep: true,
      handler(newVal) {
        if (newVal.open) return;
        // 팝업 닫기 처리
        switch (newVal.type) {
          case 'termsOfUse':
          case 'privacyPolicy':
            this.termPopup.open = false;
            break;
          case 'settings':
            this.settings.open = false;
            break;
          case 'support':
            this.supportPopUpData.open = false;
            break;
          default:
            return;
        }
        if (newVal.forceFocus) {
          setTimeout(() => {
            this.$refs[`footer_${newVal.type}`][0].focus();
          }, 30);
        }
      },
    },
  },
  methods: {
    async openTermPopup(event, type) {
      // beacon
      this.beacon.updateFunctionObject(type);
      // 팝업 등장
      switch (type) {
        case 'channelInfo':
          window.open(this.$router.resolve({ name: 'channelInfo' }).href);
          break;
        case 'termsOfUse':
        case 'privacyPolicy':
          this.termPopup = {
            type: type,
            description: this.footerItems.find(item => item.type === type).description,
            open: true,
            forceFocus: event.type === 'keydown',
          };
          break;
        case 'notSell':
          window.open('https://www.lg.com/us/caprivacy', '_blank');
          break;
        case 'settings':
          this.settings = {
            open: true,
            type: type,
            step: 1,
            forceFocus: event.type === 'keydown',
          };
          break;
        case 'support':
          this.supportPopUpData = {
            open: true,
            type: type,
            forceFocus: event.type === 'keydown',
          };
          break;
        default:
          // go through
          break;
      }
    },
    async initialize() {
      this.initialized = true;
      // 디폴트 Footer items
      let footerItems = [
        { type: 'channelInfo', title: this.$t('components.mainFooter.LG-Channels') },
        { type: 'notSell', title: this.$t('components.mainFooter.Do-not-sell') },
        { type: 'settings', title: this.$t('components.mainFooter.settings') },
        { type: 'support', title: this.$t('components.mainFooter.ask') },
        { type: 'copyright', title: '© 2022 LG Electronics. All rights reserved.' },
      ];
      // 약관동의단계에서 저장된 eulainfo 사용
      let data = await SI.eulainfo();
      if (data?.result) {
        try {
          let euladata = data.value;
          euladata.sort((a, b) => a.order - b.order);
          euladata.forEach(term => {
            let item = {
              type: term.type,
              title: term.title || term.type,
              description: term.description.replace(/\n/g, '<br />') || '',
              order: term.order,
              open: false,
            };
            footerItems.splice(item.order, 0, item);
          });
        } catch {
          console.error('[mainFooter] failed to parse eulainfo data');
          this.updateErrorStatus(200);
        }
      }
      this.footerItems = footerItems;
      this.footer_height = this.$refs.footer?.offsetHeight ?? 0;
    },
    updateErrorStatus(code) {
      this.$store.dispatch('setPopupStatus', {
        type: 'error',
        open: true,
        code: code,
      });
    },
  },
  async mounted() {
    if (!this.initialized) await this.initialize();
  },
};
</script>

<style lang="scss">
footer a {
  float: left;
}
footer .terms a:focus:not(:focus-visible):before {
  border: 0px;
  outline: none !important;
}
footer .terms:last-child {
  pointer-events: none;
}
</style>
