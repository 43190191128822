import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/appbus';
import './plugins/appmain';
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
Vue.config.productionTip = false;
import videojsContribEme from 'videojs-contrib-eme';
import i18n from './i18n';
import 'babel-polyfill';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faVolumeMute, faVolumeUp);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueMoment);
Vue.use(VueMeta);

// const VueAxe = require('vue-axe').default
// Vue.use(VueAxe)
// env 파일 로드
// console.log(process.env)
new Vue({
  router,
  store,
  videojsContribEme,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
