<template>
  <div class="channels-table" id="channel-list">
    <div class="list-table" id="list-table-program">
      <div class="guide_list_wrap" ref="channellist" :style="{ overflow: popupOpened ? 'hidden' : 'auto' }">
        <!-- 카테고리 리스트 -->
        <div v-for="(category, idx) in categories" :key="idx" role="list" v-show="category.channels.length > 0">
          <!-- 채널 리스트 -->
          <div
            ref="channelitem"
            role="button"
            tabindex="0"
            v-for="(channel, i) in category.channels"
            :key="i"
            :id="'channelitem_' + i"
            :class="{ playing_channel: nowProgramId === channel.channelId }"
            @click="clickChannel($event, channel, i)"
            @keydown.prevent.space.enter="clickChannel($event, channel, i)"
            @mouseenter="channelFocus(i)"
            @mouseleave="channelBlur"
            @focus="channelFocus(i)"
            @blur="channelBlur"
            @mouseup="channelBlur"
          >
            <div class="guide_item_wrap">
              <a class="guide_item" :style="`cursor:${programCheck(channel) ? '' : 'default'};} `">
                <span class="hide_text" v-if="nowProgramId === channel.channelId">{{ getTextdata('Selected') }}</span>
                <div
                  class="now_wrap"
                  v-show="nowProgramId === channel.channelId"
                  :style="`border: ${nowProgramId === channel.channelId ? '1.5px solid #bd0838' : ''}`"
                >
                  <div class="now_tag">{{ getTextdata('now-tag') }}</div>
                </div>
                <div class="thumb" :style="getChannelThumbnailStyle(channel)">
                  <img :src="channel.channelLogoUrl || ''" @error="thumbLoadFailed" />
                  <span class="ch_name" v-if="channel.channelNumber">{{ 'IP-' + channel.channelNumber }}</span>
                </div>
                <div
                  class="thumb_live"
                  :class="{ mouse_zoom: !isTouchDevice && focusedItemIndex === i, touch_zoom: isTouchDevice }"
                >
                  <img :src="getChannelThumbnail(channel)" @error="thumbLoadFailed" />
                </div>
                <div class="txt_wrap">
                  <div ref="wrapRef" v-if="checkValidSchedule(channel)">
                    <div class="ellipsis">
                      <span class="name titleName" ref="titleRef"> {{ getChannelTitle(channel) }}</span>
                    </div>
                    <div class="ellipsis">
                      <span class="time" v-if="programCheck(channel).programTitle !== getTextdata('no-information')">
                        {{ $moment(new Date(programCheck(channel).startDateTime)).format('hh:mm A') }} -
                        {{ $moment(new Date(programCheck(channel).endDateTime)).format('hh:mm A') }}
                      </span>
                    </div>
                  </div>
                  <div v-else ref="wrapRef">
                    <div class="ellipsis" style="cursor:defalut">
                      <span class="name" ref="titleRef"> {{ getTextdata('no-information') }}</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- 프로그램 없음 -->
        <div class="no-program" v-if="categories[0].channels.length === 0">
          {{ getTextdata('no-program') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoMixin from '../mixins/video';
import { mapGetters } from 'vuex';
export default {
  mixins: [videoMixin],
  inject: ['beacon'],

  props: {
    categories: Array,
    selectedTab: Object,
    player: Object,
    channelInfo: Object,
    programInfo: Object,
    searchedChannel: Object,
    tabBottom: Number,
    isFullscreen: Boolean,
  },
  data() {
    return {
      playing: '',
      nowProgramId: '', // 현재 재생 채널
      recentProgram: [], //최근 시청 채널
      deeplinkTarget: null, // 딥링크 컨텐츠
      popUpData: { open: false, channelName: '', channelLogoUrl: '', programs: [] }, // 프로그램 정보 팝업
      interval: '', //현재 시간 업데이트 인터벌
      nowTime: '', //현재 시간
      focusedChannelElement: null,
      mutedStore: null,
      autoScrollLocked: false,
      firstAutoScrolled: false,
      defaultImgSrc: '',

      focusedItemIndex: -1,
      playingItemIndex: -1,
    };
  },
  watch: {
    popUpData: {
      deep: true,
      handler(newVal) {
        this.$store.dispatch('setProgramPopupData', newVal);
        if (newVal.open) {
          this.$emit('openedProgramPopup', newVal.channelId);
        }
      },
    },
    programPopUpData: {
      deep: true,
      handler(newVal) {
        if (!newVal.open && this.popUpData.open) {
          this.popUpData = newVal;
          if (newVal.forceFocus) {
            setTimeout(() => {
              this.$refs.channelitem[this.playingItemIndex].focus();
            }, 30);
          }
        }
      },
    },
    screenSize: {
      deep: true,
      handler() {
        this.focusedItemIndex = this.playingItemIndex = -1;
        setTimeout(() => {
          this.updatePlayingItemIndex();
        }, 100);
      },
    },
    screenMode: {
      deep: true,
      handler() {
        this.scrollIntoView();
      },
    },
    channelInfo: {
      deep: true,
      handler(newVal) {
        if (this.popUpData.open) {
          this.popUpData.programs = newVal.programs;
        }
      },
    },
    programInfo: {
      deep: true,
      handler() {
        this.updatePlayingItemIndex();
      },
    },
    nowProgramId: {
      deep: true,
      handler() {
        // 재생중인 아이템 index
        this.updatePlayingItemIndex();
        if (this.deeplinkTarget?.chId) {
          // 딥링크에 의해 재생 -> 화면 안으로 스크롤
          this.scrollIntoView();
          this.deeplinkTarget = null;
        }
      },
    },
    searchedChannel: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.channelId) {
          let target = this.getFirstContentByChannelId(newVal.channelId);
          this.$nextTick(() => {
            let index = this.categories[0].channels.findIndex(channel => channel.channelId === newVal.channelId);
            if (index >= 0) {
              this.changeChannel(target.channel, target.program);
              this.scrollIntoView(this.$refs.channelitem[index], true);
              if (newVal.eventType === 'keydown') {
                this.$refs.channelitem[index].focus();
              }
            }
          });
        }
      },
    },
    selectedTab: {
      handler(newVal, oldVal) {
        // 현재 탭 재선택 처리 (top <-> 현재채널)
        if (!this.firstAutoScrolled || newVal.index !== oldVal.index || newVal.timestamp === oldVal.timestamp) return;
        if (!this.elementInViewport(this.$refs.channelitem[0])) {
          this.scrollIntoView(this.$refs.channelitem[0]);
        } else {
          this.scrollIntoView();
        }
      },
    },
    categories: {
      deep: true,
      handler(newVal, oldVal) {
        // 최초 진입, 탭 변경 처리
        if (!this.firstAutoScrolled || newVal[0].categoryCode !== oldVal[0].categoryCode) {
          this.scrollIntoView();
        }
        this.updatePlayingItemIndex();
      },
    },
    marqueeIndex: {
      handler(newVal, oldVal) {
        if (oldVal >= 0) this.stopMarquee(oldVal);
        if (newVal >= 0) this.startMarquee(newVal);
      },
    },
    popupOpened: {
      handler(newVal) {
        if (!newVal && !this.firstAutoScrolled) {
          this.scrollIntoView();
        }
      },
    },
    isFullscreen: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal === true && !newVal) {
          setTimeout(() => {
            this.scrollIntoView();
          }, 200);
        }
      },
    },
  },
  created() {
    // caller
    let caller = sessionStorage.getItem('deeplink-caller');
    if (caller) {
      this.beacon.setLaunchByLink(caller, true);
    }
    sessionStorage.removeItem('deeplink-caller');
    // deeplink target
    let target = sessionStorage.getItem('deeplink-target');
    if (target == 'detail') {
      let validTypes = ['tabId', 'vodId', 'chId'];
      validTypes.forEach(type => {
        let content = sessionStorage.getItem('deeplink-' + type);
        if (content) {
          this.deeplinkTarget = {};
          this.deeplinkTarget[type] = content;
          sessionStorage.removeItem('deeplink-' + type);
        }
      });
      console.log(`[deeplink] deeplink to ${target}`, this.deeplinkTarget);
    }
    sessionStorage.removeItem('deeplink-target');

    //최근 시청 채널 가져오기
    let recent = localStorage.getItem('recent-program');
    this.recentProgram = recent ? JSON.parse(recent) : [];

    //현재 시간 업데이트 인터벌 설정
    this.nowTime = new Date();
    this.interval = setInterval(() => {
      this.nowTime = new Date();
    }, 1000);
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      browserName: 'getBrowserName',
      popupOpened: 'getPopupOpened',
      programPopUpData: 'getProgramPopupData',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.channels.${whattext}`) ?? '';
      };
    },
    marqueeIndex() {
      if (this.popupOpened) return -1;
      // marquee 우선순위 : 포커스 위치(mouseover) > 재생중 프로그램
      else return this.focusedItemIndex >= 0 ? this.focusedItemIndex : this.playingItemIndex;
    },
    elementInViewport() {
      return el => {
        let rect = el.getBoundingClientRect();
        let listrect = this.$refs.channellist.getBoundingClientRect();
        return (
          rect.top >= this.tabBottom &&
          rect.left >= listrect.left &&
          rect.right <= listrect.right &&
          rect.bottom <= listrect.bottom
        );
      };
    },
    getAutoScrollOption() {
      return {
        behavior: 'smooth',
        block: this.screenMode === 'landscape' ? 'start' : 'end',
        inline: 'nearest',
      };
    },
    isTouchDevice() {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.defaultImgSrc = require('../assets/images/thumb/default.png');
      this.focusedItemIndex = this.playingItemIndex;
    });

    this.categories.forEach(category => this.initChannels(category));

    // 최초 재생할 채널 & 프로그램 체크
    let targetId =
      this.deeplinkTarget?.chId ??
      (this.recentProgram.length > 0
        ? this.recentProgram[this.recentProgram.length - 1].channelId
        : this.categories[0].channels[0].channelId);
    let target = this.getFirstContentByChannelId(targetId);
    this.changeChannel(target.channel, target.program, true);
  },
  methods: {
    updatePlayingItemIndex() {
      this.playingItemIndex = this.categories[0].channels.findIndex(channel => this.nowProgramId === channel.channelId);
    },
    initChannels(category) {
      category.channels.forEach(channel => {
        // 첫 아이템 시작 시간 맞추기
        let startDateTime = this.$moment(new Date());
        let endDateTime = this.$moment(new Date()).add(6, 'hours');
        let programsPerChannel = channel.programs.filter(rawProgram => {
          return (
            this.$moment(new Date(rawProgram.endDateTime)).isAfter(startDateTime) &&
            this.$moment(new Date(rawProgram.startDateTime)).isBefore(endDateTime)
          );
        });
        channel.programs = programsPerChannel;

        channel.programs.forEach((program, index, thisarray) => {
          if (program && index < thisarray.length - 1) {
            this.checkNoInfoPrograms(channel, program, index);
          }
        });
      });
    },
    checkNoInfoPrograms(channel, program, index) {
      // No infroamtion 삽입
      let endDateTime = this.$moment(new Date(program.endDateTime));
      let nextStartTime = this.$moment(new Date(channel.programs[index + 1].startDateTime));

      let diff = this.$moment.duration(nextStartTime.diff(endDateTime)).asMinutes();
      if (diff > 1) {
        channel.programs.splice(index + 1, 0, {
          programTitle: this.getTextdata('no-information'),
          startDateTime: program.endDateTime,
          endDateTime: channel.programs[index + 1].startDateTime,
        });
      }
    },
    scrollIntoView(target, forced = false) {
      this.$nextTick(() => {
        if (this.popupOpened) return;
        if (this.autoScrollLocked && !forced) return;
        if (this.categories[0].channels?.length == 0) return;
        if (this.isFullscreen) return;
        else {
          if (!target) {
            let index = this.categories[0].channels.findIndex(channel => channel.channelId === this.nowProgramId);
            target = this.$refs.channelitem[index < 0 ? 0 : index];
          }
          this.doScrollIntoView(target);
        }
      });
    },
    doScrollIntoView(target) {
      this.autoScrollLocked = true;
      setTimeout(() => {
        target.scrollIntoView(this.getAutoScrollOption);
        this.checkResetScrollNeeded();
      }, 100);
    },
    checkResetScrollNeeded() {
      // iPad 등에서 최초 진입시 전체영역 top 위치가 함께 내려가는 케이스 보정
      let resetScrollNeeded = !this.firstAutoScrolled && this.screenMode === 'landscape';
      setTimeout(
        () => {
          this.autoScrollLocked = false;
          if (resetScrollNeeded) {
            window.scrollTo(0, 0);
          }
          this.firstAutoScrolled = true;
        },
        resetScrollNeeded ? 1000 : 300
      );
    },
    saveRecentProgramData(channel) {
      if (!this.recentProgram) return;

      let checkDup = this.recentProgram.findIndex(e => e.channelId === channel.channelId);
      if (checkDup === -1 && this.recentProgram.length >= 5) {
        this.recentProgram.splice(0, 1);
      } else {
        this.recentProgram.splice(checkDup, 1);
      }
      this.recentProgram.splice(checkDup, 1);
      this.recentProgram.push({ channelId: channel.channelId });
      localStorage.setItem('recent-program', JSON.stringify(this.recentProgram));
    },
    setProgramData(program, mediaStaticUrl) {
      let withCredentials;
      let keySystems = {};
      this.clearTracks();

      // program meta로 받아오는 값들 설정
      if (program) {
        if (program.token) {
          withCredentials = true;
        } else {
          withCredentials = false;
        }
        let drmType;
        if (program.drmType === 'playready') {
          drmType = 'com.microsoft.playready';
        } else if (program.drmType === 'widevine') {
          drmType = 'com.widevine.alpha';
        }

        keySystems[drmType] = {
          url: program.drmUrl,
        };

        if (program.headerName) {
          keySystems[drmType].licenseHeaders = {
            [program.headerName]: program.token,
          };
        }
      }
      this.player.player.poster(program?.thumbnailUrl ?? '');

      let src = {
        src: mediaStaticUrl,
        type: program?.type,
        withCredentials: withCredentials,
        keySystems: keySystems,
      };

      this.$store.state.playSrc = src;
      // 영상 실행
      let playSrc = Object.assign({}, src);
      playSrc = this.getUrlWithSSAIParameters(playSrc.src);
      this.player.player.src(playSrc);

      if (this.mutedStore !== null) {
        this.player.player.muted(this.mutedStore);
        this.mutedStore = null;
      }
      this.changeCaptionStyle(program?.track);
    },
    clickChannel(event, channel, i) {
      //편성표 카드 클릭
      let program = this.programCheck(channel);
      this.$store.state.programPopUpIndex = i;

      if (this.nowProgramId === channel.channelId) {
        // 프로그램 팝업 오픈
        if (!program) return;
        else {
          let target = this.$refs.channelitem[i];
          // beacon
          this.beacon.saveDetail(program, channel);
          // 중복 클릭 -> 영상 재생 중일 시 인포카드 열기
          this.popUpData = {
            open: true,
            channelName: channel.channelName,
            color: channel.color,
            borderRadius: channel.borderRadius,
            channelLogoUrl: channel.channelLogoUrl,
            channelId: channel.channelId,
            programs: channel.programs,
            top: target.getBoundingClientRect().top,
            forceFocus: event.type === 'keydown',
          };
          return;
        }
      } else {
        // 채널전환
        this.changeChannel(channel, program);
      }
    },
    startMarquee(i) {
      this.$nextTick(() => {
        const width = this.$refs.channellist.clientWidth / 70;
        this.$refs.titleRef[i].style.animation = '';
        var wrapperWidth = this.$refs.wrapRef[i].clientWidth;
        var titleWidth = this.$refs.titleRef[i].clientWidth;
        if (wrapperWidth < titleWidth) {
          const seconds = (titleWidth / wrapperWidth) * width;
          this.$refs.titleRef[i].style.animation = `marquee ${seconds}s linear infinite`;
        } else {
          this.$refs.titleRef[i].style.animation = '';
        }
      });
    },
    stopMarquee(i) {
      const wrapperWidth = this.$refs.wrapRef[i].clientWidth;
      const titleWidth = this.$refs.titleRef[i].clientWidth;
      if (wrapperWidth < titleWidth) {
        this.$refs.titleRef[i].style.animation = '';
      }
    },
    channelBlur() {
      this.focusedItemIndex = -1;
    },
    channelFocus(i) {
      this.focusedItemIndex = i;
    },
    changeChannel(channel, program, firstEnter) {
      if (channel.mediaStaticUrl) {
        // 최근 시청 localStorage 저장
        this.saveRecentProgramData(channel);
        this.setProgramData(program, channel.mediaStaticUrl);
        this.nowProgramId = channel.channelId;
        this.$emit('changechannel', channel);
        this.popUpData = {
          open: false,
          channelName: channel.channelName,
          color: channel.color,
          borderRadius: channel.borderRadius,
          channelLogoUrl: channel.channelLogoUrl,
          channelId: channel.channelId,
          programs: channel.programs,
          forceFocus: false,
        };

        if (!firstEnter) {
          this.player.player.play();
        }
      } else {
        // error 팝업 열기
        this.mutedStore = this.player.player.muted();
        this.player.player.reset();
        let channelNoInfo = JSON.parse(JSON.stringify(channel));
        this.$emit('changechannel', channelNoInfo);
        this.nowProgramId = channel.channelId;
        this.player.player.poster('');

        this.player.player.src({ src: 'No Information', type: 'No Information' });
        this.player.player.load();
      }
    },
    clearTracks() {
      let list = this.player.player.remoteTextTrackEls() || [];
      let i = list.length;

      while (i--) {
        let track = list[i];
        this.player.player.removeRemoteTextTrack(track);
      }
    },

    changeCaptionStyle(tracks) {
      // 자막 popup 아아템 변경
      if (!tracks) return;
      for (let track of tracks) {
        this.player.player.addRemoteTextTrack(track);
      }
    },
    programCheck(channel) {
      return channel.programs[0] ? channel.programs[0] : '';
    },
    getChannelThumbnail(channel) {
      return this.programCheck(channel)?.thumbnailUrl || this.programCheck(channel)?.imageUrl || this.defaultImgSrc;
    },
    getChannelTitle(channel) {
      return this.programCheck(channel)?.programTitle || channel.channelName;
    },
    getChannelThumbnailStyle(channel) {
      return `background-color:${channel.color || ''}; border-radius:${channel.borderRadius ??
        '0'}px; text-align:center`;
    },
    checkValidSchedule(channel) {
      const program = this.programCheck(channel);
      if (!program) return false;
      if (program.startDateTime === program.endDateTime) return false;
      if (this.$moment(new Date(program.endDateTime)).isBefore(this.$moment(this.nowTime))) return false;
      if (this.$moment(new Date(program.startDateTime)).isAfter(this.$moment(this.nowTime))) return false;
      return true;
    },
    getFirstContentByChannelId(channelId) {
      let target_channel, target_program;
      target_channel = this.categories[0].channels.find(channel => {
        return channel.channelId == channelId;
      });
      if (!target_channel) {
        // 매칭되는 채널이 없는 경우 첫번째 채널로 이동
        console.log('[deeplink] cannot find target channel. go to first channel', channelId);
        target_channel = this.categories[0].channels[0];
      }
      target_program = target_channel.programs.find(
        program => this.$moment(new Date(program.endDateTime)).isBefore(this.$moment(new Date())) === false
      );
      return {
        channel: target_channel,
        program: target_program ?? undefined,
      };
    },
    thumbLoadFailed(e) {
      e.target.src = this.defaultImgSrc;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
.guide_list_wrap {
  position: relative;
}
.guide_list_wrap:first-child {
  widows: 100%;
}
.guide_list_wrap > div > div[id^='channelitem'] {
  margin: 0 3.6rem 0 1.2rem;
  @media screen and (max-width: 1240px) and (orientation: landscape) {
    margin: 0 1.2rem;
  }
  @media screen and (max-width: 1240px) and (orientation: portrait) {
    margin: 0;
  }
  &:hover {
    .mouse_zoom {
      transform: scale(1.25);
      transition-duration: 0.5s;
    }
    .touch_zoom {
      animation: thumb_zoom 1s ease;
    }
  }
  &:focus {
    a.guide_item {
      background-color: rgba(148, 148, 148, 0.3);
    }
    .name {
      text-decoration: underline;
    }
  }
}
@keyframes thumb_zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.playing_channel {
  background-color: rgba(148, 148, 148, 0.3);
}
.guide_item {
  .txt_wrap {
    flex: 1;
    overflow: hidden;
  }
  .name {
    width: fit-content;
    display: inline-block;
    white-space: nowrap;
    line-height: 1rem;
  }
  .thumb {
    img {
      width: 60%;
    }
  }
  .thumb_live {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 115px;
    }
  }
  .wrapRef {
    cursor: default;
  }
}
.channels-table {
  color: white;
  scroll-behavior: smooth;
}

.list-table {
  color: white;
  // white-space: nowrap;
  width: 100%;
}
.channel-logo-info {
  position: sticky;
  left: 0px;
  padding: 10px 20px;
  display: flex;
  background-color: black;
  z-index: 2;
  .thumnail-card {
    border-radius: 12px;
    margin-left: 20px;
    width: 4em;
    height: 3.6em;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: flex;
  }
  .channel-name {
    text-align: center;
    font-size: 13px;
    color: grey;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .channel-logo-img {
    border-radius: 0px;
    width: 50%;
    height: 50%;
  }
}
.program-card {
  border-radius: 10px;
  margin-left: 15px;
  height: fit-content;
  background-color: #1d1d1d;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  width: 300px;
  .program-title {
    margin: 0px !important;
    background-color: transparent !important;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  .program-time {
    margin: 0px !important;
    font-weight: 300;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    height: 18px;
    span {
      color: white;
    }
  }
}
.playing-card {
  background-color: #bd0838 !important;
}
.program-list {
  display: flex;
  padding-left: 20px;
  align-items: center;

  .available-program {
    background-color: #272727;
    cursor: pointer;
  }
  .expired-program {
    background-color: #1d1d1d !important;
    cursor: default !important;
  }
  .beforeStart-program {
    background-color: #1d1d1d !important;
    cursor: pointer !important;
  }
}
.no-program {
  color: rgba(255, 255, 255, 0.5);
  padding: 1em 1.5em;
  font-size: 0.8rem;
  text-align: center;
  word-break: keep-all;
}
</style>
