import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// 에러 화면
import notFound from '../views/errorpage/NotFound.vue';
import notAuth from '../views/errorpage/NotAuthenticated.vue';

// 메인 화면
import lge_channels_web from '../views/main/lge_channels_web.vue';
const live = () => import(/* webpackChunkName: "main" */ '../views/live/live');
const channelInfo = () => import(/* webpackChunkName: "main" */ '../views/channelInfo/channelInfo');
const unsupported = () => import(/* webpackChunkName: "main" */ '../views/unsupported/unsupported');
const ondemand = () => import(/* webpackChunkName: "main" */ '../views/vod/ondemand');

const routes = [
  { path: '*', name: 'notfound', component: notFound },
  { path: '/block', name: 'block', component: notAuth },
  {
    path: '/',
    name: 'Home',
    component: lge_channels_web,
    children: [
      {
        path: '/',
        component: live,
        name: 'live',
      },
      {
        path: '/',
        component: ondemand,
        name: 'ondemand',
      },
    ],
  },
  {
    path: '/channelInfo',
    name: 'channelInfo',
    component: channelInfo,
  },
  {
    path: '/unsupported',
    name: 'unsupported',
    component: unsupported,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
