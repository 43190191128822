import axios from 'axios';

function axiosInstance(type = '', baseUrl = undefined) {
  let isDevMode = location.hostname.replace('www.', '').split('.')[0] !== 'lgchannels';
  let instance;
  // let country = localStorage.getItem('country');
  let reqHeader = {
    'X-Device-Country': 'US',
    'X-Device-Language': 'en',
    'X-Device-Type': 'WEB',
  };

  if (type === 'local') {
    let localHostURL = `${window.location.protocol}//${window.location.host}/`;
    instance = axios.create({ baseURL: localHostURL });
  } else if (type === 'beacon' && baseUrl) {
    instance = axios.create({
      baseURL: `${baseUrl}/api/v1/beacon/`,
      headers: reqHeader,
    });
  } else {
    instance = axios.create({
      baseURL: `${location.protocol}//${isDevMode ? 'dev-api' : 'api'}.lgchannels.com/api/v1.0/`,
      headers: reqHeader,
    });
  }

  instance.interceptors.request.use(
    function(config) {
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function(response) {
      try {
        // Do something with response data
      } catch (e) {
        console.error(e);
      }
      return response;
    },
    function(error) {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return instance;
}

export default axiosInstance;
