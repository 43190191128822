<template>
  <!-- Popup : 이용약관(동의) -->
  <div class="popup pop_agree active" v-if="termShow">
    <div class="pop_container">
      <div class="terms">
        <h2 style="line-height: initial;">
          {{ termChange.length > 0 ? getTextdata('edit_title') : getTextdata('text1-1') }}
          <span>{{ termChange.length > 0 ? '' : getTextdata('text1-2') }}</span> <br />
          {{ termChange.length > 0 ? '' : getTextdata('text1-3') }}
        </h2>
        <div class="guide">
          <span>{{ termChange.length > 0 ? getTextdata('edit_content') : getTextdata('text2-2') }}</span>
          <br />
          {{ termChange.length > 0 ? '' : getTextdata('text3') }}
          <span>{{ termChange.length > 0 ? '' : getTextdata('text6') }}</span>
        </div>
        <ul class="agree" style="padding-left:0px">
          <li v-for="(term, i) in terms" :key="i">
            <div class="checkbox">
              <input
                :id="`term_checkbox_${i}`"
                :checked="term.checked"
                type="checkbox"
                @keydown.prevent.shift.tab="goPrev('checkbox', i)"
                @keydown.prevent.tab.exact="goNext('checkbox', i)"
                @keydown.prevent.enter="toggleTerm(term)"
              />
              <label
                autofocus
                ref="term_checkbox"
                :tabindex="i + 2"
                :for="`term_checkbox_${i}`"
                :style="termBackgroundStyle(term)"
                @click="toggleTerm(term)"
              >
                <span v-if="term.mandatory">({{ getTextdata('text8') }})</span>
                <span :id="`chk_agree_${i + 2}`" :style="{ color: termTextStyle(term) }"> {{ term.title }}</span>
              </label>
            </div>
            <a
              v-if="term.description"
              class="btn btn_detail"
              role="button"
              ref="term_description"
              :tabindex="i + 3"
              :aria-labelledby="`chk_agree_${i + 2}`"
              :id="`term_description_${i}`"
              @click="openTermPopup($event, term.type)"
              @keydown.prevent.enter="openTermPopup($event, term.type)"
              @keydown.prevent.shift.tab="goPrev('description', i)"
              @keydown.prevent.tab.exact="goNext('description', i)"
            >
              {{ getTextdata('text9') }}
            </a>
          </li>
        </ul>
        <button
          type="button"
          class="btn btn_agree"
          id="agree_btn"
          ref="confirm_button"
          tabindex="-1"
          :disabled="!isAllChecked"
          @click="toLive"
          @keydown.prevent.enter="toLive"
          @keydown.prevent.shift.tab="goPrev('button')"
        >
          {{ getTextdata('text12') }}
        </button>
      </div>
    </div>
    <termDialog v-if="popUpData.open" :popUpData="popUpData"></termDialog>
  </div>
  <!-- // Popup : 이용약관(동의) -->
  <!-- 팝업 -->
</template>

<script>
import { termDialog } from '@/components/index';
import SI from '@/apis/service_interface';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    termDialog,
  },
  data() {
    return {
      popUpData: {
        //팝업 데이터
        initialOpen: false,
        open: false,
        title: '',
        lastUpdated: '',
        version: '',
        mandatory: false,
      },
      terms: [],
      termShow: undefined, //약관 화면 유무
      termChange: [], //약관 수정 항목
      inputWork: false,
    };
  },

  watch: {
    getPopupStatus: {
      deep: true,
      handler(newVal) {
        if (!newVal.open && this.popUpData.open) {
          this.popUpData.open = false;
          if (newVal.forceFocus) {
            setTimeout(() => {
              this.$refs.term_description[parseInt(newVal.order) - 1].focus();
            }, 100);
          }
        }
      },
    },
    getAgreed: {
      deep: true,
      immediate: true,
      async handler(agreed) {
        if (agreed) {
          this.termShow = false;
          document.documentElement.style.setProperty('overflow', 'auto', 'important');
        } else {
          // 약관 미동의 -> 동의팝업 표시
          this.termShow = true;
          document.documentElement.style.setProperty('overflow', 'hidden', 'important');
          await this.initialize();
          setTimeout(() => {
            // 포커스
            if (!this.getPopupStatus.open && this.termShow) {
              this.$refs.term_checkbox?.[0]?.focus();
            }
          }, 100);
        }
      },
    },
    getEulaVersions: {
      deep: true,
      immediate: true,
      handler(versions) {
        if (versions) {
          versions.forEach(version => {
            if (!version.checked) {
              this.termChange.push(version.type);
            }
          });
        }
      },
    },
  },

  computed: {
    ...mapGetters(['getAgreed', 'getEulaVersions', 'getPopupStatus']),
    isAllChecked() {
      // mandatory이면서 checked되지 않은 항목 존재 -> false, 그 외 true
      return !this.terms.find(term => term.mandatory && !term.checked);
    },
    termTextStyle() {
      return term => {
        return this.termChange.indexOf(term.type) < 0 ? '' : 'red';
      };
    },
    termBackgroundStyle() {
      return term => {
        return term.mandatory ? {} : { backgroundImage: 'none' };
      };
    },
    getTextdata() {
      return whattext => {
        return this.$t(`components.termLayout.${whattext}`) ?? '';
      };
    },
  },
  methods: {
    ...mapActions(['changeEulaVersions']),
    toggleTerm(term) {
      if (term.mandatory) term.checked = !term.checked;
    },
    toLive() {
      // 동의 버튼 클릭
      this.$store.dispatch('changeAgreed', true);
      let termsToSave = this.terms.map(term => {
        return {
          type: term.type,
          version: term.version,
          lastUpdated: term.lastUpdated,
          checked: term.checked || !term.mandatory,
        };
      });
      this.changeEulaVersions(termsToSave);
    },
    openTermPopup(event, type) {
      // 팝업 등장
      let item = this.terms.find(term => term.type === type);
      this.popUpData = {
        ...item,
        open: true,
        initialOpen: true,
        forceFocus: event.type === 'keydown',
      };
    },
    updateErrorStatus(code) {
      this.$store.dispatch('setPopupStatus', {
        type: 'error',
        open: true,
        code: code,
      });
    },
    async initialize() {
      let data = await SI.eulainfo();
      if (data?.result) {
        try {
          // 서버 데이터 -> 약관 항목 생성
          let eulainfo = data.value;
          let terms = [];
          eulainfo.forEach(term => {
            let item = {
              type: term.type,
              title: term.title || '',
              description: term.description.replace(/\n/g, '<br />'),
              lastUpdated: term.lastUpdated || '',
              version: term.version || '',
              mandatory: term.mandatory || false,
              order: +term.order,
              open: false,
              checked: term.checked || !term.mandatory || false,
            };
            terms.push(item);
          });
          this.terms = [...terms].sort((a, b) => a.order - b.order);
        } catch (e) {
          console.error('[termLayout] failed to parse eulainfo data');
          this.updateErrorStatus(200);
        }
      }
    },
    goPrev(fromwhere, index = -1) {
      switch (fromwhere) {
        case 'checkbox':
          if (index - 1 >= 0) this.$refs.term_description[index - 1].focus();
          break;
        case 'description':
          this.$refs.term_checkbox[index].focus();
          break;
        case 'button':
          this.$refs.term_description[this.terms.length - 1].focus();
          break;
        default:
          // do nothing
          break;
      }
    },
    goNext(fromwhere, index = -1) {
      switch (fromwhere) {
        case 'checkbox':
          this.$refs.term_description[index].focus();
          break;
        case 'description':
          if (index + 1 === this.terms.length) this.$refs.confirm_button.focus();
          else this.$refs.term_checkbox[index + 1].focus();
          break;
        default:
          // do nothing
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.pop_agree {
  color: white;
  font-family: 'LG SUXR', sans-serif !important;
  .line-height-setting {
    line-height: initial;
  }
  .btn_detail {
    color: white;
  }
}
.no-agree-padding {
  padding: 0px !important;
}
.termLayout-checkbox:focus-visible {
  border: 1px solid white;
}
</style>
