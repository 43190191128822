export default {
  data() {
    return {};
  },
  methods: {
    getUrlWithSSAIParameters(mediaUrl) {
      let _regexp_macro = /\[APP_NAME\]|\[COUNTRY\]|\[DEVICE_TYPE\]|\[IP\]|\[CONTENT_LIVE\]|\[UA\]|\[URL\]|\[LMT\]|\[DNS\]|\[DEVICE_ID\]/gi;
      let _ssaiMediaUrl = mediaUrl;
      _ssaiMediaUrl = _ssaiMediaUrl.replace(_regexp_macro, match => {
        if (match) {
          return this.getValue(match);
        }
      });
      let _regexp_macro_ignore = /\[IFA\]|\[IFA_TYPE\]|\[GDPR\]|\[GDPR_CONSENT\]|\[US_PRIVACY\]|\[APP_STOREURL\]|\[APP_BUNDLE\]|\[DEVICE_MAKE\]|\[DEVICE_MODEL\]|\[TARGETAD_ALLOWED\]|\[RESOLUTION\]|\[APP_VERSION\]/gi;

      _ssaiMediaUrl = _ssaiMediaUrl.replace(_regexp_macro_ignore, match => {
        if (match) {
          return '';
        }
      });
      return _ssaiMediaUrl;
    },
    getValue(macroValue) {
      let _macro = macroValue.toUpperCase();
      switch (_macro) {
        case '[APP_NAME]':
          return 'lgchannels_web';
        case '[COUNTRY]':
          return this.$store.state.country;
        case '[DEVICE_TYPE]':
          return 'pc';
        case '[IP]': // TBD
          return '0.0.0.0';
        case '[CONTENT_LIVE]': // TBD(추후 VOD contents 추가 되는 경우)
          return '1';
        case '[UA]':
          return encodeURIComponent(navigator.userAgent);
        case '[URL]':
          return encodeURIComponent(location.origin);
        case '[LMT]':
          return this.$store.getters.getLmt ? 1 : 0; // TBD(정책에 맞게 업데이트)
        case '[DNS]':
          return this.$store.getters.getDns ? 1 : 0; // TBD(정책에 맞게 업데이트)
        case '[DEVICE_ID]':
          return this.$store.getters.getDeviceid; // TBD(정책에 맞게 업데이트)
      }
      return '';
    },
  },
  provide() {
    return {
      videoMixin: this,
    };
  },
};
