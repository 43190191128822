<template>
  <div>
    <div class="pop_dim" style="display:block"></div>
    <div id="exampop04" class="popup center pop_sharing mobile_change active">
      <div class="pop_container">
        <div class="pop_content">
          <h3>{{ errorTitle }}</h3>
          <p><br />{{ errorDescription }}</p>
          <p>
            {{ errorGuide }}<br /><br />
            {{ errorCode }}
          </p>
          <p class="errmsg">
            {{ popUpData.message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    popUpData: Object,
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    errorTitle() {
      if (this.popUpData.code > 1000) {
        return this.$t(`components.errorPopup.serverErrorTitle`);
      } else if (this.popUpData.code >= 700) {
        return this.$t(`components.errorPopup.mediaErrorTitle`);
      } else return this.$t(`components.errorPopup.dataErrorTitle`);
    },
    errorDescription() {
      if (this.popUpData.code > 1000) {
        return this.$t(`components.errorPopup.serverErrorDesc`);
      } else if (this.popUpData.code >= 700) {
        return this.$t(`components.errorPopup.mediaErrorDesc`);
      } else return this.$t(`components.errorPopup.dataErrorDesc`);
    },
    errorGuide() {
      return this.$t('components.errorPopup.errorGuide');
    },
    errorCode() {
      return this.$t('components.errorPopup.errorCode', { number: this.popUpData.code });
    },
  },
  methods: {
    popUpClose() {
      this.popUpData.open = false;
    },
  },
};
</script>
