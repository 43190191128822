@@ -0,0 +1,41 @@
<template>
  <div>
    <div class="pop_dim" style="display:block" @click="popUpClose"></div>
    <div id="exampop04" class="popup center pop_sharing mobile_change">
      <div class="pop_container">
        <div class="pop_content">
          <p style="text-align:center">
            {{ popupTitle }}
            <br style="display:block" />
            <br style="display:block" />
            {{ popupDescription }}
          </p>
        </div>
        <div class="pop_btn">
          <button
            id="support_popup_button"
            type="button"
            ref="closeButton"
            class="btn type_red popup_close"
            @click="popUpClose"
            @keydown.prevent.enter="popUpClose"
          >
            {{ popupOK }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    popUpData: Object,
  },
  watch: {
    popUpData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$store.dispatch('setPopupStatus', {
          open: newVal.open,
          type: newVal.type,
        });
        if (newVal.forceFocus) {
          setTimeout(() => {
            this.$refs.closeButton.focus();
          }, 30);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    popupTitle() {
      return this.$t('components.supportPopUp.title');
    },
    popupDescription() {
      return this.$t('components.supportPopUp.description');
    },
    popupOK() {
      return this.$t('components.supportPopUp.ok');
    },
  },
  methods: {
    popUpClose(event) {
      this.$store.dispatch('setPopupStatus', {
        ...this.popUpData,
        open: false,
        forceFocus: event.type === 'keydown',
      });
    },
  },
};
</script>
