<template>
  <!-- Popup : 이용약관 -->
  <div>
    <div id="exampop01" class="popup center pop_terms" v-if="popUpData.open">
      <div class="pop_dim" style="display:block;" @click="closePopup"></div>
      <div
        class="pop_container no_focus_outlined"
        tabindex="0"
        ref="term_dialog_container"
        id="termDialog_container"
        style="z-index:991"
      >
        <div class="pop_header">
          <h2 class="logo_tit">
            <span style="margin-left: 5px; "
              >{{ getTextdata('title_text1') }}<span>{{ getTextdata('title_text2') }}</span></span
            >
          </h2>
        </div>
        <div class="pop_content">
          <h3 class="tit">{{ popUpTitle }}</h3>
          <!-- 약관 내용 -->
          <div
            tabindex="0"
            class="terms_item"
            id="termDialog_content"
            ref="termDialog_content"
            style="line-height:initial; text-align:initial; white-space:pre-line"
            v-text="brChange(popUpData.description)"
            @keydown.prevent.tab="tabPressed"
          ></div>
          <!-- // 약관 내용 -->
        </div>
        <button
          type="button"
          class="btn btn_close popup_close"
          id="termDialog_close"
          ref="termDialog_close"
          @click="closePopup"
          @keydown.prevent.tab="tabPressed"
          @keydown.prevent.enter="closePopup"
        >
          {{ getTextdata('close') }}
        </button>
      </div>
    </div>
  </div>
  <!-- Popup : 이용약관 -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    popUpData: Object,
  },
  watch: {
    popUpData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$store.dispatch('setPopupStatus', {
          open: newVal.open,
          type: newVal.type,
        });
        if (newVal.forceFocus) {
          setTimeout(() => {
            this.$refs.termDialog_content.focus();
          }, 30);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      popupStatus: 'getPopupStatus',
    }),
    popUpTitle() {
      return this.popUpData.type === 'termsOfUse'
        ? this.$t('components.mainFooter.term-use')
        : this.$t('components.mainFooter.privacy-policy');
    },
    getTextdata() {
      return whattext => {
        return this.$t(`components.termDialog.${whattext}`) ?? '';
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    brChange(text) {
      return text.replace(/<br ?\/?>/gi, '\n');
    },
    tabPressed(event) {
      let nextTargetRef = event.target.id === 'termDialog_close' ? 'termDialog_content' : 'termDialog_close';
      this.$refs[nextTargetRef].focus();
    },
    closePopup(event) {
      this.$store.dispatch('setPopupStatus', {
        ...this.popUpData,
        open: false,
        forceFocus: event.type === 'keydown',
      });
    },
  },
};
</script>

<style lang="scss">
@import '../assets/css/style.css';
.pop_terms {
  display: block;
  .pop_container {
    color: white;
  }
}
</style>
