<template>
  <v-dialog hide-overlay v-model="popUpData.open">
    <div>
      <div class="pop_dim" style="display:block" @click="popUpData.open = false"></div>
      <div
        class="popup pop_pg_info mobile_change active"
        :style="{ top: `${getPopupTop ? getPopupTop + 'px' : ''}`, bottom: `${getPopupTop ? '' : '20px'}` }"
      >
        <div
          class="pop_container pop_container_info no_focus_outlined"
          ref="program_pop_container"
          id="program_pop_container"
          tabindex="0"
        >
          <div class="pop_content">
            <div class="live_status">
              <div class="status_container">
                <div class="thumb">
                  <div
                    class="ch_info logo"
                    :style="
                      `background-color:rgba(0, 0, 0, 0); border-radius:${
                        popUpData.borderRadius ? popUpData.borderRadius : '0'
                      }px`
                    "
                  >
                    <img
                      :src="popUpData.channelLogoUrl || ''"
                      :alt="popUpData.channelName"
                      style="width:60%"
                      @error="thumbLoadFailed"
                    />
                  </div>
                </div>
                <div class="txt_wrap" ref="wrapBox">
                  <div class="ellipsis" ref="titleBox">
                    {{ newProgramList[showingData].programTitle }}
                  </div>
                  <span class="time">
                    {{ $moment(new Date(newProgramList[showingData].startDateTime)).format('MM/DD hh:mm A') }}
                    -
                    {{ $moment(new Date(newProgramList[showingData].endDateTime)).format('MM/DD hh:mm A') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="pg_info">
              {{
                newProgramList[showingData].description
                  ? newProgramList[showingData].description
                  : getTextdata('no-information')
              }}
            </div>
            <div class="scroll">
              <h3 style="color:white; font-size: 0.9rem;">{{ getTextdata('next-program') }}</h3>

              <div class="guide_list_wrap" :style="`--data-content:'${getTextdata('live')}'`">
                <ul>
                  <li
                    v-for="(program, i) in newProgramList"
                    :key="i"
                    :class="isPlaying(program) ? 'playing' : showingData === 0 && i === 1 ? 'no-top-border' : ''"
                  >
                    <a
                      tabindex="0"
                      class="guide_item"
                      role="button"
                      ref="program_next"
                      :style="
                        `cursor:${program.programTitle === getTextdata('no-information') ? 'default' : 'default'}`
                      "
                      @click="changePopUp(program, i)"
                      @mouseenter="channelFocus(i)"
                      @mouseleave="channelBlur"
                      @focus="channelFocus(i)"
                      @blur="channelBlur"
                      @keydown="event => programPopUpKey(event, program, i)"
                    >
                      <div class="thumb_live">
                        <img
                          :src="program.thumbnailUrl || program.imageUrl || ''"
                          :alt="program.thumbnailUrl ? program.programTitle : getTextdata('no-information')"
                          @error="thumbLoadFailed"
                        />
                      </div>
                      <div class="txt_wrap" ref="itemWrapBox">
                        <div class="ellipsis">
                          <span class="title" ref="itemTitleBox">{{ program.programTitle }}</span>
                        </div>
                        <span class="time">
                          {{ $moment(new Date(program.startDateTime)).format('hh:mm A') }} -
                          {{ $moment(new Date(program.endDateTime)).format('hh:mm A') }}</span
                        >
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn_close popup_close"
            ref="program_popup_close"
            @keydown="closePopUp"
            @click="closePopUp"
          >
            {{ getTextdata('close') }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    popUpData: Object,
    programInfo: Object,
    channelInfo: Object,
    sharePopUpData: Object,
  },
  components: {},
  inject: ['beacon'],
  data() {
    return {
      focusingData: -1,
      showingData: 0, // 보여지는 프로그램 인덱스
      newProgramList: [], // 예정 프로그램 목록
      containerRef: null,
      popupHeight: 0,
    };
  },
  watch: {
    popUpData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.initialize();
        if (newVal.open && newVal.forceFocus) {
          setTimeout(() => {
            this.$refs.program_next[0].focus();
          }, 30);
        }
      },
    },
    screenSize: {
      deep: true,
      handler() {
        if (this.popupOpened) {
          if (this.focusingData < 0) {
            this.$refs.titleBox.style.animation = '';
            this.titleMarquee();
          } else {
            let index = this.focusingData;
            this.focusingData = -1;
            setTimeout(() => (this.focusingData = index), 30);
          }
        }
      },
    },
    showingData: {
      immediate: true,
      handler(index) {
        this.checkPopupHeight(index);
      },
    },
    focusingData: {
      immediate: true,
      handler(newVal, oldVal) {
        // 현재 위치에 marquee 적용
        if (newVal < 0) {
          this.titleMarquee();
        } else {
          this.itemMarquee(newVal);
        }
        // 기존 애니메이션 삭제
        if (oldVal === -1) {
          this.$refs.titleBox.style.animation = '';
        } else if (oldVal >= 0 && this.newProgramList.length > 0) {
          this.$refs.itemTitleBox[oldVal].style.animation = '';
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      popupOpened: 'getPopupOpened',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.programPopUp.${whattext}`) ?? '';
      };
    },
    getPopupTop() {
      if (this.screenMode === 'portrait' || this.screenSize.width <= 1000) return null;

      if (this.isPopupFromProgramInfo) return null;
      if (this.popupHeight + 20 > this.screenSize.height) return null;
      else if (this.popUpData.top + this.popupHeight + 20 >= this.screenSize.height) {
        return this.screenSize.height - this.popupHeight - 20;
      } else return this.popUpData.top;
    },
    isPlaying() {
      return program => {
        return this.$moment(new Date()).isBetween(
          this.$moment(new Date(program.startDateTime)),
          this.$moment(new Date(program.endDateTime))
        );
      };
    },
    isPopupFromProgramInfo() {
      return this.popUpData.top === null;
    },
  },

  created() {
    // 첫 아이템 시작 시간 맞추기
    this.initialize();
  },
  mounted() {
    this.defaultImgSrc = require('../assets/images/thumb/default.png');
    this.$refs.program_pop_container.scrollTo(0, 0);
    this.containerRef = this.$refs.program_pop_container;
    this.checkPopupHeight();
  },
  methods: {
    initialize() {
      // 첫 아이템 시작 시간 맞추기
      let nowTime = this.$moment(new Date());
      let sixHourAdded = this.$moment(new Date()).add(6, 'hours');
      let timeLimitList = this.popUpData.programs.filter(rawProgram => {
        return (
          rawProgram.programId === this.programInfo.programId ||
          this.$moment(new Date(rawProgram.startDateTime)).isBetween(nowTime, sixHourAdded) ||
          this.$moment(new Date(rawProgram.endDateTime)).isBetween(nowTime, sixHourAdded)
        );
      });
      this.newProgramList = timeLimitList;
      this.focusingData = -1;
    },
    changePopUp(program, i) {
      // beacon
      this.beacon.saveDetail(this.programInfo, this.channelInfo, program);

      // 프로그램 목록 클릭
      if (program.programTitle !== this.getTextdata('no-information')) {
        this.showingData = i;
      }
    },
    channelFocus(index) {
      this.focusingData = index;
    },
    channelBlur() {
      this.focusingData = -1;
    },
    startMarquee(wrapBox, titleBox, i = -1) {
      this.$nextTick(() => {
        var targetWrapper = i >= 0 ? this.$refs[wrapBox][i] : this.$refs[wrapBox];
        var targetTitle = i >= 0 ? this.$refs[titleBox][i] : this.$refs[titleBox];
        targetTitle.style.animation = '';
        const targetWrapperWidth = targetWrapper.clientWidth;
        const targetTitleWidth = targetTitle.clientWidth;
        if (targetWrapperWidth < targetTitleWidth) {
          const seconds = targetTitleWidth / targetWrapperWidth;
          targetTitle.style.animation = `marquee ${seconds * 7}s linear infinite`;
        } else {
          targetTitle.style.animation = ``;
        }
      });
    },
    itemMarquee(i) {
      this.startMarquee('itemWrapBox', 'itemTitleBox', i);
    },
    titleMarquee() {
      this.startMarquee('wrapBox', 'titleBox');
    },
    checkPopupHeight() {
      this.$nextTick(() => {
        this.popupHeight = this.containerRef?.offsetHeight ?? 0;
      });
    },
    programPopUpKey(event, program, i) {
      if (event.keyCode == 9) {
        if (i === this.newProgramList.length - 1) {
          if (!event.shiftKey) {
            event.preventDefault();
            this.$refs.program_popup_close.focus();
            this.$refs.program_pop_container.scrollTo(0, 0);
          }
        } else if (i === 0) {
          if (event.shiftKey) {
            event.preventDefault();
            this.$refs.program_popup_close.focus();
            this.$refs.program_pop_container.scrollTo(0, 0);
          }
        }
      } else if (event.keyCode == 32 || event.keyCode == 13) {
        event.preventDefault();
        this.changePopUp(program, i);
      }
    },
    closePopUp(event) {
      event.preventDefault();
      if (event.type === 'keydown') {
        switch (event.keyCode) {
          case 9:
            this.$refs.program_next[!event.shiftKey ? 0 : this.newProgramList.length - 1].focus();
            break;
          case 13:
          case 32:
            this.$store.dispatch('setProgramPopupData', {
              ...this.popUpData,
              open: false,
              forceFocus: true,
            });
            break;
          default:
            // do nothing
            break;
        }
      } else {
        this.$store.dispatch('setProgramPopupData', {
          ...this.popUpData,
          open: false,
          forceFocus: false,
        });
      }
    },
    shareTab(event) {
      if (event.keyCode == 9) {
        if (event.shiftKey) {
          this.$refs.program_popup_close.focus();
          event.preventDefault();
        }
      }
    },
    popUpscrollTop() {
      this.$refs.program_pop_container.scrollTo(0, 0);
    },
    thumbLoadFailed(e) {
      e.target.src = this.defaultImgSrc;
    },
  },
};
</script>

<style lang="scss">
.guide_item .txt_wrap {
  flex: 1;
  overflow: hidden;
}
.guide_item .title {
  width: fit-content;
  display: inline-block;
  white-space: nowrap;
  line-height: 1rem;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}

.no-top-border::after {
  display: none;
}
.altFont {
  font-size: 0.6rem;
}
.popup {
  font-family: 'LG SUXR', sans-serif;
}
.playing .guide_item .thumb_live:after {
  content: var(--data-content);
  color: white;
}
@media screen and (max-width: 1240px) and (orientation: portrait) {
  .pop_pg_info {
    height: 0px;
    width: 0px;
  }
  .pop_container_info {
    position: fixed !important;
  }
}
.guide_list_wrap {
  overflow-x: hidden;
}
</style>
