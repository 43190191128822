<template>
  <v-container class="main_layout">
    <!-- header -->
    <mainHeader :class="headerClass" :ui_status="ui_status" @offsetHeight="headerHeightChanged"></mainHeader>
    <!-- content -->
    <div role="main">
      <router-view :header_height="header_height" :footer_height="footer_height" @uiStatus="uiStatusChanged" />
    </div>
    <!-- footer -->
    <mainFooter
      class="footerMargin"
      :style="`position:${$route.name === 'term' ? 'absolute' : ''}`"
      @offsetHeight="footerHeightChanged"
    ></mainFooter>
  </v-container>
</template>
<script>
import { mainHeader, mainFooter } from '@/components/index';
import beacon from '@/mixins/beacon';
import { mapGetters } from 'vuex';
export default {
  components: { mainHeader, mainFooter },
  mixins: [beacon],
  data() {
    return {
      // screenWidth: window.innerWidth,
      header_height: 0,
      footer_height: 0,
      ui_status: false,
    };
  },
  watch: {
    popupOpened: {
      deep: true,
      handler(opened) {
        document.documentElement.style.setProperty('overflow', opened ? 'hidden' : 'auto', 'important');
      },
    },
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      popupOpened: 'getPopupOpened',
    }),
    nowWidth() {
      return this.screenSize.width;
    },
    headerClass() {
      return this.nowWidth < 1032 ? 'header-layout' : '';
    },
  },
  created() {
    this.$store.dispatch('setDeviceInformation');
    this.$store.dispatch('initAdvertisement');
  },
  mounted() {
    this.$store.dispatch('setScreenMode');
    this.$store.dispatch('setScreenSize');
    window.addEventListener('resize', () => {
      this.$store.dispatch('setScreenMode');
      this.$store.dispatch('setScreenSize');
    });
  },
  methods: {
    headerHeightChanged(value) {
      this.header_height = value;
    },
    footerHeightChanged(value) {
      this.footer_height = value;
    },
    uiStatusChanged(status) {
      this.ui_status = status;
    },
  },
};
</script>
<style lang="scss" scoped>
.main_layout {
  max-width: 100vw !important;
  background-color: black;
  min-height: 100vh;
  padding: 0px;
}
.footerMargin {
  bottom: 0px;
  width: inherit;
}
.header-layout {
  background-color: black;
  position: sticky;
  top: 0px;
  z-index: 3;
}
</style>
