<template>
  <v-app>
    <router-view></router-view>
    <v-dialog
      v-model="loading"
      fullscreen
      overlay-color="transparent"
      transition="dialog-center-transition"
      content-class="mainloadingBar"
    >
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate :size="100" :width="5" color="grey lighten-1"> </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<script>
import SI from '@/apis/service_interface';
import { version } from '../package.json';
export default {
  name: 'App',
  metaInfo() {
    return {
      meta: [
        {
          property: 'og:title',
          content: `${document.title}`,
          vmid: 'og:title',
        },
        {
          property: 'og:image',
          content: '/lgch_logo.png',
          vmid: 'og:image',
        },
        {
          property: 'og:url',
          content: `${document.URL}`,
          vmid: 'og:url',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:description',
          content: 'Welcome to LG Channels',
          vmid: 'og:description',
        },
      ],
    };
  },
  data() {
    return {
      loading: false,
      version: version,
    };
  },
  created() {
    console.error(`[lgchannels-web] version check ${this.version}`);

    // 브라우저 테스트
    const sp_browsers = ['Firefox', 'SamsungBrowser', 'Edg', 'Chrome', 'Safari'];
    const usp_browsers = ['Opera', 'OPR', 'Trident', 'Edge/'];
    if (
      !usp_browsers.find(value => navigator.userAgent.indexOf(value) >= 0) &&
      sp_browsers.find(value => navigator.userAgent.indexOf(value) >= 0)
    ) {
      return;
    } else if (navigator.userAgent.indexOf('ThinQ') >= 0) {
      // included in ThinQ app
      return;
    } else {
      this.$router.replace({ name: 'unsupported' });
    }
  },
  async mounted() {
    // 언어 셋팅
    const language = (await SI.getLanguage()) ?? 'en';
    if (this.$i18n.locale !== language) {
      this.$i18n.locale = language;
    }
    console.log('[lgchannels-web] language', this.$i18n.locale);
  },
};
</script>
<style lang="scss">
@import './assets/css/global.scss';
@import './assets/css/style.css';
</style>
