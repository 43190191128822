'use strict';

import Vue from 'vue';
import appbus from './appbus';

class AppMain {
  constructor() {
    this._loading = {
      show: this.showloading.bind(this),
      hide: this.hideloading.bind(this),
    };

    this._bus = appbus;
  }

  get loading() {
    return this._loading;
  }

  showloading() {
    this._bus.$emit('loading::show');
  }
  hideloading() {
    this._bus.$emit('loading::hide');
  }
}

let _appmain = new AppMain();

const appmain = {};
appmain.install = function(_vue) {
  // console.log(options)
  _vue.appmain = _appmain;
  window.appmain = _appmain;
  Object.defineProperties(_vue.prototype, {
    appmain: {
      get() {
        return _appmain;
      },
    },
    $appmain: {
      get() {
        return _appmain;
      },
    },
  });
};

Vue.use(appmain);

export default appmain;
